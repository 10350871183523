import 'rsuite/dist/rsuite.min.css';
import React, { useEffect, useState, Fragment } from "react";
import { useNavigate } from 'react-router-dom';
import { Table, DatePicker } from 'rsuite';
import { apiRequest } from "../../../../api/apiUtils";
import { Col, Row, Button } from "react-bootstrap";
import CurrencyFormat from 'react-currency-format';
import {
  EditableCell,
  ActionCell,
  fetchData,
  addNewRow,
  calculateTableHeight
} from "../../../../views/modules/table/table_function";
import dateFormat, { masks } from "dateformat";

let token;
const { Column, HeaderCell, Cell } = Table;

let currency_format = JSON.parse(localStorage.getItem("currency"));

const generateDefaultData = () => {
  return {
    id: Math.random(),
    status: 'EDIT'
  };
};

export default function Bill({ onBillChange, templateDb, dataDb, templateKey, dataId }) {
  const [tableValues, setTableValues] = useState({});
  const [billFormat, setBillFormat] = useState({});
  const [taxFormat, setTaxFormat] = useState({});
  const [billData, setBillData] = useState({});
  const [taxData, setTaxData] = useState({});
  const [tax_data, setTax_Data] = useState([]); //To Save Fetched Tax Data
  const [bill_data, setBill_Data] = useState([]); //To Save Fetched Bill Data

  const [subtotal, setSubTotal] = useState(0);
  const [taxAmount, setTaxAmount] = useState(0);
  const [total, setTotal] = useState(0);
  const [deleteAction, setDeleteAction] = useState(false);
  const [billError, setBillError] = useState('');
  const [taxError, setTaxError] = useState('');

  let navigate = useNavigate();
  token = localStorage.getItem("token")

  const stateVariable = {
    subtotal,
    taxAmount,
    total
  }

  useEffect(() => {
    const fetchInfo = async () => {
      setBillFormat(await fetchData(templateDb, templateKey, token, navigate));
      setTaxFormat(await fetchData(templateDb, "bill_tax", token, navigate));
      if (dataId)
        setTableValues(await fetchData(dataDb, dataId, token, navigate));
    }
    fetchInfo();
  }, []);

  useEffect(() => {
    if (tableValues.bill) {
      // const initialData = tableValues.bill.bill_items?.map(item => ({ ...item, status: 'SAVE' }));
      setSubTotal(tableValues.bill.subtotal)
      setTotal(tableValues.bill.total)
      if (tableValues.bill.bill_items && Object.keys(tableValues.bill.bill_items).length > 0) {
        setBillData(tableValues.bill.bill_items);
        setBill_Data(tableValues.bill.bill_items);
      }
      else
        setBillData([generateDefaultData()]);
      if (tableValues.bill.tax_items && Object.keys(tableValues.bill.tax_items).length > 0) {
        tableValues.bill.tax_items ? setTaxData(tableValues.bill.tax_items) : setTaxData([generateDefaultData()]);
        setTaxAmount(tableValues.bill.taxAmount);
        setTax_Data(tableValues.bill.tax_items);
      }
      else
        setTaxData([generateDefaultData()]); //Provide array in default Row to get initial row
    }
    else {
      setBillData([generateDefaultData()]);
      setTaxData([generateDefaultData()]);
    }
  }, [tableValues]);

  useEffect(() => {
    const fetchInfo = async () => {
      if (deleteAction) {
        await handleSubmit();
        setDeleteAction(false);
        await onBillChange();
      }
    }
    fetchInfo();
  }, [subtotal, total, taxAmount, deleteAction]);

  const calculateTax = (totalAmount, taxData) => {
    let taxAmount = 0;
    Object.keys(taxData).forEach(key => {
      const rowData = taxData[key];
      const taxRate = parseFloat(rowData.tax_rate);
      taxAmount += (totalAmount * taxRate) / 100;
    });
    return taxAmount;
  };

  const calculateTotal = () => {
    let taxAmount = 0
    const subtotalAmount = (billData?.filter(item => item.status === 'SAVE')).reduce((sum, item) => sum + (parseFloat(item.amount, 10) || 0), 0);
    if (taxData && Object.keys(taxData.filter(item => item.status === 'SAVE')).length > 0) {
      taxAmount = calculateTax(subtotalAmount, taxData.filter(item => item.status === 'SAVE'));
      let total = subtotalAmount + taxAmount;
      setTaxAmount(taxAmount);
      setTotal(total);
    }
    else {
      setTaxAmount(taxAmount);
      setTotal(subtotalAmount);
    }
    setSubTotal(subtotalAmount);
  }

  const handleSubmit = async () => {
    calculateTotal();
    let bill = {};
    onBillChange();
    if (tableValues.bill) {
      bill = {
        ...tableValues.bill,
        bill_items: bill_data,
        tax_items: tax_data,
        subtotal: subtotal,
        taxAmount: taxAmount,
        total: total
      }
    }
    else {
      const currentDate = new Date().toISOString();
      bill = {
        bill_items: bill_data,
        tax_items: tax_data,
        date: currentDate,
        subtotal: subtotal,
        taxAmount: taxAmount,
        total: total
      };
    }
    console.log(bill);
    try {
      const response = await apiRequest(`${dataDb}/post/${dataId}`,
        'POST',
        { bill },
        {
          'Content-Type': 'multipart/form-data',
          'Authorization': token
        });
      setDeleteAction(true);
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error.message === 'Invalid Token') {
        console.log("error called");
        navigate('/sign-in?token=Invalid-Token');
      }
    }
  };

  return (
    <Fragment>
      <Row>
        <Col sm='12' >
          <div className="iq-card">
            {
              billFormat.title ? (
                <div className="iq-card-header d-flex justify-content-between">
                  <div className="iq-header-title">
                    <h4 className="card-title">
                      {billFormat.title}
                    </h4>
                  </div>
                </div>
              ) : (<></>)
            }
            <div className="iq-card-body">
              <Row>
                {
                  tableValues.bill?.billNumber ? (
                    <Col className="text-right">
                      <div><b>
                        Bill Number : </b>
                        {tableValues.bill.billNumber}
                      </div>
                      <div><b>
                        Bill Date : </b>
                        {<DatePicker
                          value={tableValues?.bill?.date ? new Date(tableValues.bill.date) : ''}
                          format={localStorage.getItem('dateFormat')}
                          plaintext
                        />}
                      </div>
                    </Col>
                  ) : (
                    <Col className="text-right">
                      <div><b>
                        Bill Date : </b>
                        {<DatePicker
                          value={new Date()}
                          format={localStorage.getItem('dateFormat')}
                          plaintext
                        />}
                      </div>
                    </Col>
                  )
                }
                <Col className="text-end">
                  <span className="">
                    <Button
                      size='sm'
                      variant='primary'
                      className="btn btn-primary mb-1 me-2 p-2"
                      onClick={() => addNewRow(billData, setBillData, generateDefaultData)}>
                      <i className="ri-add-fill"></i>
                      Add Bill Row
                    </Button>
                  </span>
                </Col>
              </Row>
              <div id="table" className="table-editable">
                <div className="table-responsive-md w-100">
                  <Table height={calculateTableHeight(billData)} data={billData} >
                    {
                      billFormat.fields?.map((inputs, index) => {
                        return (
                          <Column width={200} key={inputs.field_name}>
                            <HeaderCell className='text-center'>{inputs.field_label}</HeaderCell>
                            <EditableCell dataKey={inputs.field_name} inputs={inputs} dataState={billData} setDataState={setBillData} setError={setBillError} height={550} />
                          </Column>
                        )
                      })
                    }
                    <Column>
                      <HeaderCell className='text-center'>Save</HeaderCell>
                      <ActionCell className='text-center' dataKey="save" handleSubmit={handleSubmit} generateDefaultData={generateDefaultData} dataState={billData} setDataState={setBillData} fetchedData={bill_data} setFetchedData={setBill_Data} fields={billFormat.fields} setError={setBillError} />
                    </Column>
                    <Column>
                      <HeaderCell className='text-center'>Delete</HeaderCell>
                      <ActionCell className='text-center' dataKey="delete" handleSubmit={handleSubmit} dataState={billData} setDataState={setBillData} fetchedData={bill_data} setFetchedData={setBill_Data} setError={setBillError} />
                    </Column>
                  </Table>
                  <div className="text-center font-weight-bold text-danger">{billError}</div>
                </div>
              </div>
              <Row className='mt-4'>
                <Col className="text-end">
                  <span className="">
                    <Button
                      size='sm'
                      variant='primary'
                      className="btn btn-primary mb-1 me-2 p-2"
                      onClick={() => addNewRow(taxData, setTaxData, generateDefaultData)}>
                      <i className="ri-add-fill"></i>
                          Add Tax Row
                    </Button>
                  </span>
                </Col>
              </Row>
              <div id="table" className="table-editable">
                <div className="table-responsive-md w-100">
                  <Table height={calculateTableHeight(taxData)} data={taxData} >
                    {
                      taxFormat.fields?.map((inputs, index) => {
                        return (
                          <Column width={200} key={inputs.field_name}>
                            <HeaderCell className='text-center'>{inputs.field_label}</HeaderCell>
                            <EditableCell dataKey={inputs.field_name} inputs={inputs} dataState={taxData} setDataState={setTaxData} setError={setTaxError} height={100} />
                          </Column>
                        )
                      })
                    }
                    <Column>
                      <HeaderCell className='text-center'>Save</HeaderCell>
                      <ActionCell className='text-center' dataKey="save" handleSubmit={handleSubmit} generateDefaultData={generateDefaultData} dataState={taxData} setDataState={setTaxData} fetchedData={tax_data} setFetchedData={setTax_Data} fields={taxFormat.fields} setError={setTaxError} />
                    </Column>
                    <Column>
                      <HeaderCell className='text-center'>Delete</HeaderCell>
                      <ActionCell className='text-center' dataKey="delete" handleSubmit={handleSubmit} dataState={taxData} setDataState={setTaxData} fetchedData={tax_data} setFetchedData={setTax_Data} setError={setTaxError} />
                    </Column>
                  </Table>
                  <div className="text-center font-weight-bold text-danger">{taxError}</div>
                </div>
              </div>
              <Row>
                <Col md={3} className="text-right">
                  <table className="table">
                    <tbody>
                      {
                        billFormat.display_fields?.map((inputs, index) => {
                          return (
                            <tr key={index}>
                              <td className='text-center'>
                                <b>{inputs.field_label}</b>
                              </td>
                              <td className='text-end'>
                                <CurrencyFormat
                                  displayType='text'
                                  value={stateVariable[inputs.field_value]}
                                  {...currency_format}
                                />
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </Fragment >
  );
};