import 'rsuite/dist/rsuite.min.css';
import React, { useEffect, useState, Fragment } from "react";
import { useNavigate } from 'react-router-dom';
import { Table } from 'rsuite';
import { apiRequest } from "../../../../api/apiUtils";
import { Col, Row, Button } from "react-bootstrap";
import {
  EditableCell,
  ActionCell,
  fetchData,
  addNewRow,
  calculateTableHeight
} from "../../../../views/modules/table/table_function"
let token;
const { Column, HeaderCell, Cell } = Table;

const generateDefaultData = () => {
  return {
    id: Math.random(),
    status: 'EDIT'
  };
};

export default function Prescription({ templateDb, dataDb, templateKey, dataId }) {
  const [tableValues, setTableValues] = useState({});
  const [prescriptionFormat, setPrescriptionFormat] = useState({});
  const [prescriptionData, setPrescriptionData] = useState({});
  const [prescription_data, setPrescription_Data] = useState([]); //To Save Fetched Prescription Data

  const [deleteAction, setDeleteAction] = useState(false);
  const [prescriptionError, setPrescriptionError] = useState('');

  let navigate = useNavigate();
  token = localStorage.getItem("token")

  useEffect(() => {
    const fetchInfo = async () => {
      setPrescriptionFormat(await fetchData(templateDb, templateKey, token, navigate));
      if (dataId)
        setTableValues(await fetchData(dataDb, dataId, token, navigate));
    }
    fetchInfo();
  }, []);

  useEffect(() => {
    if (tableValues.prescription) {
      // const initialData = tableValues.prescription.prescription_items?.map(item => ({ ...item, status: 'SAVE' }));
      if (tableValues.prescription.prescription_items && Object.keys(tableValues.prescription.prescription_items).length > 0) {
        setPrescriptionData(tableValues.prescription.prescription_items);
        setPrescription_Data(tableValues.prescription.prescription_items);
      }
      else
        setPrescriptionData([generateDefaultData()]);
    }
    else
      setPrescriptionData([generateDefaultData()]);
  }, [tableValues]);

  useEffect(() => {
    const fetchInfo = async () => {
      if (deleteAction) {
        await handleSubmit();
        setDeleteAction(false);
      }
    }
    fetchInfo();
  }, [deleteAction]);

  const handleSubmit = async () => {
    let prescription = {};
    // console.log(prescription_data, tableValues.prescription)
    if (prescription_data.length) {
      prescription = {
        prescription_items: prescription_data
      }
    }
    else {
      prescription = {
        empty_data: "Empty"
      };
    }
    console.log({ prescription });
    try {
      const response =
        await apiRequest(`${dataDb}/post/${dataId}`, 'POST', { prescription }, { 'Content-Type': 'multipart/form-data', 'Authorization': token });
      setDeleteAction(true);
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error.message === 'Invalid Token') {
        console.log("error called");
        navigate('/sign-in?token=Invalid-Token');
      }
    }
  };

  return (
    <Fragment>
      <Row>
        <Col sm='12' >
          <div className="iq-card">
            {
              prescriptionFormat.title ? (
                <div className="iq-card-header d-flex justify-content-between">
                  <div className="iq-header-title">
                    <h4 className="card-title">
                      {prescriptionFormat.title}
                    </h4>
                  </div>
                </div>
              ) : (<></>)
            }
            <div className="iq-card-body">
              <Row>
                <Col className="text-end">
                  <span className="">
                    <Button
                      size='sm'
                      variant='primary'
                      className="btn btn-primary mb-1 me-2 p-2"
                      onClick={() => addNewRow(prescriptionData, setPrescriptionData, generateDefaultData)}>
                      <i className="ri-add-fill"></i>
                          Add Prescription Row
                    </Button>
                  </span>
                </Col>
              </Row>
              <div id="table" className="table-editable">
                <div className="table-responsive-md w-100">
                  <Table height={calculateTableHeight(prescriptionData)} data={prescriptionData} >
                    {
                      prescriptionFormat.fields?.map((inputs, index) => {
                        return (
                          <Column width={200} key={inputs.field_name}>
                            <HeaderCell className='text-center'>{inputs.field_label}</HeaderCell>
                            <EditableCell dataKey={inputs.field_name} inputs={inputs} dataState={prescriptionData} setDataState={setPrescriptionData} setError={setPrescriptionError} height={550} />
                          </Column>
                        )
                      })
                    }
                    <Column>
                      <HeaderCell className='text-center'>Save</HeaderCell>
                      <ActionCell className='text-center' dataKey="save" handleSubmit={handleSubmit} generateDefaultData={generateDefaultData} dataState={prescriptionData} setDataState={setPrescriptionData} fetchedData={prescription_data} setFetchedData={setPrescription_Data} fields={prescriptionFormat.fields} setError={setPrescriptionError} />
                    </Column>
                    <Column>
                      <HeaderCell className='text-center'>Delete</HeaderCell>
                      <ActionCell className='text-center' dataKey="delete" handleSubmit={handleSubmit} dataState={prescriptionData} setDataState={setPrescriptionData} fetchedData={prescription_data} setFetchedData={setPrescription_Data} setError={setPrescriptionError} />
                    </Column>
                  </Table>
                  <div className="text-center font-weight-bold text-danger">{prescriptionError}</div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Fragment >
  );
};