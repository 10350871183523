// import React, { Fragment, useState } from "react";
// import { Col, Row, Form, Button } from "react-bootstrap";
// import Chart from "react-apexcharts";

// const ApexChartPage = () => {
//   const [newValue1, setNewValue1] = useState("");
//   const [newValue2, setNewValue2] = useState("");
//   const [categories, setCategories] = useState([]);
//   const [chartData, setChartData] = useState({
//     options: {
//       chart: {
//         height: 350,
//         type: "area"
//       },
//       dataLabels: {
//         enabled: false
//       },
//       stroke: {
//         curve: "smooth"
//       },
//       xaxis: {
//         type: "datetime",
//         categories: categories
//       },
//       tooltip: {
//         x: {
//           format: "dd/MM/yy HH:mm",
//         }
//       },
//     },
//     series: [
//       {
//         name: "series1",
//         data: []
//       },
//       {
//         name: "series2",
//         data: []
//       },
//     ],
//   });

//   const handleInputChange = () => {
//     const newSeries1Data = [...chartData.series[0].data, Number(newValue1)];
//     const newSeries2Data = [...chartData.series[1].data, Number(newValue2)];
//     const currentTime = new Date().toISOString();

//     const newCategories = [...categories, currentTime];

//     setCategories(newCategories);

//     setChartData({
//       ...chartData,
//       options: {
//         ...chartData.options,
//         xaxis: {
//           ...chartData.options.xaxis,
//           categories: newCategories,
//         },
//       },
//       series: [
//         {
//           name: "Systolic",
//           data: newSeries1Data,
//         },
//         {
//           name: "Diastolic",
//           data: newSeries2Data,
//         },
//       ],
//     });

//     setNewValue1("");
//     setNewValue2("");
//   };

//   return (
//     <Fragment>
//       <Row>
//         <Col sm="12" lg="6">
//           <Form>
//             <Form.Group controlId="newValue1">
//               <Form.Label>Enter Systolic</Form.Label>
//               <Form.Control
//                 type="number"
//                 value={newValue1}
//                 onChange={(e) => setNewValue1(e.target.value)}
//               />
//             </Form.Group>
//             <Form.Group controlId="newValue2">
//               <Form.Label>Enter Diastolic</Form.Label>
//               <Form.Control
//                 type="number"
//                 value={newValue2}
//                 onChange={(e) => setNewValue2(e.target.value)}
//               />
//             </Form.Group>
//             <Button variant="primary" onClick={handleInputChange}>
//               Updated Chart
//             </Button>
//           </Form>
//           <div className="iq-card">
//             <div className="iq-card-header d-flex justify-content-between">
//               <div className="iq-header-title">
//                 <h4 className="card-title">Chart</h4>
//               </div>
//             </div>
//             <div className="iq-card-body">
//               <Chart
//                 options={chartData.options}
//                 series={chartData.series}
//                 type="area"
//                 height="350"
//               />
//             </div>
//           </div>
//         </Col>
//       </Row>
//     </Fragment>
//   );
// };

// export default ApexChartPage;


import React, { Fragment, useState } from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import Chart from "react-apexcharts";

const ApexChartPage = ({ initialSeries, initialCategories }) => {
  console.log(initialSeries);
  console.log(initialCategories);
  const [newValues, setNewValues] = useState(Array(initialSeries?.length).fill(""));
  const [categories, setCategories] = useState(initialCategories);
  const [chartData, setChartData] = useState({
    options: {
      chart: {
        height: 350,
        type: "area",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "datetime",
        categories: categories,
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
    },
    series: initialSeries?.map((serie) => ({
      name: serie.name,
      data: serie.data,
    })),
  });

  const handleInputChange = () => {
    const updatedSeriesData = chartData?.series?.map((serie, index) => ({
      ...serie,
      data: [...serie.data, Number(newValues[index])]
    }));

    const currentTime = new Date().toISOString();
    const newCategories = [...categories, currentTime];

    setCategories(newCategories);

    setChartData({
      ...chartData,
      options: {
        ...chartData.options,
        xaxis: {
          ...chartData.options.xaxis,
          categories: newCategories,
        },
      },
      series: updatedSeriesData,
    });

    setNewValues(Array(initialSeries?.length).fill(""));
  };

  const handleValueChange = (index, value) => {
    const updatedValues = [...newValues];
    updatedValues[index] = value;
    setNewValues(updatedValues);
  };

  return (
    <Fragment>
      <Row>
        <Col sm="12" lg="6">
          <Form>
            {chartData?.series?.map((serie, index) => (
              <Form.Group key={index} controlId={`newValue${index}`}>
                <Form.Label>Enter value for {serie.name}</Form.Label>
                <Form.Control
                  type="number"
                  value={newValues[index]}
                  onChange={(e) => handleValueChange(index, e.target.value)}
                />
              </Form.Group>
            ))}
            <Button variant="primary" onClick={handleInputChange}>
              Update Chart
            </Button>
          </Form>
        </Col>
        <Col sm="12" lg="6">
          <div className="iq-card">
            <div className="iq-card-header d-flex justify-content-between">
              <div className="iq-header-title">
                <h4 className="card-title">Chart</h4>
              </div>
            </div>
            <div className="iq-card-body">
              <Chart
                options={chartData.options}
                series={chartData.series}
                type="area"
                height="350"
              />
            </div>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

export default ApexChartPage;










// import React, { Fragment, useState } from "react";
// import { Col, Row, Form, Button } from "react-bootstrap";
// import Chart from "react-apexcharts";

// const ApexChartPage = ({ initialSeries, initialCategories }) => {
//   const [categories, setCategories] = useState(initialCategories);
//   const [chartData, setChartData] = useState({
//     options: {
//       chart: {
//         height: 350,
//         type: "area",
//       },
//       dataLabels: {
//         enabled: false,
//       },
//       stroke: {
//         curve: "smooth",
//       },
//       xaxis: {
//         type: "datetime",
//         categories: categories,
//       },
//       tooltip: {
//         x: {
//           format: "dd/MM/yy HH:mm",
//         },
//       },
//     },
//     series: initialSeries.map((serie) => ({
//       name: serie.name,
//       data: serie.data,
//     })),
//   });

//   const [newValues, setNewValues] = useState(Array(initialSeries.length).fill(""));
//   const [newSeriesName, setNewSeriesName] = useState("");
//   const [newSeriesInitialValue, setNewSeriesInitialValue] = useState("");

//   const handleInputChange = () => {
//     const updatedSeriesData = chartData.series.map((serie, index) => ({
//       ...serie,
//       data: [...serie.data, Number(newValues[index])],
//     }));

//     const currentTime = new Date().toISOString();
//     const newCategories = [...categories, currentTime];

//     setCategories(newCategories);

//     setChartData({
//       ...chartData,
//       options: {
//         ...chartData.options,
//         xaxis: {
//           ...chartData.options.xaxis,
//           categories: newCategories,
//         },
//       },
//       series: updatedSeriesData,
//     });

//     setNewValues(Array(chartData.series.length).fill(""));
//   };

//   const handleValueChange = (index, value) => {
//     const updatedValues = [...newValues];
//     updatedValues[index] = value;
//     setNewValues(updatedValues);
//   };

//   const handleAddNewSeries = () => {
//     if (newSeriesName && newSeriesInitialValue) {
//       const newSeries = {
//         name: newSeriesName,
//         data: [Number(newSeriesInitialValue)],
//       };

//       const currentTime = new Date().toISOString();
//       const newCategories = [...categories, currentTime];

//       setChartData((prevChartData) => ({
//         ...prevChartData,
//         series: [...prevChartData.series, newSeries],
//         options: {
//           ...prevChartData.options,
//           xaxis: {
//             ...prevChartData.options.xaxis,
//             categories: newCategories,
//           },
//         },
//       }));

//       setNewValues((prevNewValues) => [...prevNewValues, ""]);
//       setNewSeriesName("");
//       setNewSeriesInitialValue("");
//       setCategories(newCategories);
//     }
//   };

//   return (
//     <Fragment>
//       <Row>
//         <Col sm="12" lg="6">
//           <Form>
//             {chartData.series.map((serie, index) => (
//               <Form.Group key={index} controlId={`newValue${index}`}>
//                 <Form.Label>Enter value for {serie.name}</Form.Label>
//                 <Form.Control
//                   type="number"
//                   value={newValues[index]}
//                   onChange={(e) => handleValueChange(index, e.target.value)}
//                 />
//               </Form.Group>
//             ))}
//             <Button variant="primary" onClick={handleInputChange}>
//               Update Chart
//             </Button>
//           </Form>

//           <Form className="mt-4">
//             <Form.Group controlId="newSeriesName">
//               <Form.Label>New Series Name</Form.Label>
//               <Form.Control
//                 type="text"
//                 value={newSeriesName}
//                 onChange={(e) => setNewSeriesName(e.target.value)}
//               />
//             </Form.Group>
//             <Form.Group controlId="newSeriesInitialValue">
//               <Form.Label>Initial Value</Form.Label>
//               <Form.Control
//                 type="number"
//                 value={newSeriesInitialValue}
//                 onChange={(e) => setNewSeriesInitialValue(e.target.value)}
//               />
//             </Form.Group>
//             <Button variant="success" onClick={handleAddNewSeries}>
//               Add New Series
//             </Button>
//           </Form>

//           <div className="iq-card mt-4">
//             <div className="iq-card-header d-flex justify-content-between">
//               <div className="iq-header-title">
//                 <h4 className="card-title">Chart</h4>
//               </div>
//             </div>
//             <div className="iq-card-body">
//               <Chart
//                 options={chartData.options}
//                 series={chartData.series}
//                 type="area"
//                 height="350"
//               />
//             </div>
//           </div>
//         </Col>
//       </Row>
//     </Fragment>
//   );
// };

// export default ApexChartPage;
