import { Fragment, memo, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Navbar, Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import * as SettingSelector from "../../../store/setting/selectors";
import CustomToggle from "../../dropdowns";
import Fullscreen from "../../fullscreen";
import { useLogout } from "../../../router/logout";

const Header = memo(() => {
  // Fixed Header
  const [isFixed, setIsFixed] = useState(false);
  const [username, setUsername] = useState('User');
  const [show, setShow] = useState(false);
  const logout = useLogout();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 75) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };
    const user = localStorage.getItem('username');
    console.log(user);
    if (user)
      setUsername(user);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const appName = useSelector(SettingSelector.app_name);
  const [isClicked, setIsClicked] = useState(false);
  const minisidebar = () => {
    setIsClicked(isClicked);
    document.body.classList.toggle('sidebar-main');
  };

  return (
    <Fragment>
      <div className={`iq-top-navbar ${isFixed ? "fixed-header" : ""}`}>
        <div className="iq-navbar-custom">
          <div className="iq-sidebar-logo">
            <div className="top-logo">
              <Link to="index.html" className="logo">
                <img src="images/logo.png" className="img-fluid" alt="" />
                <span>{appName}</span>
              </Link>
            </div>
          </div>
          <Navbar expand="lg" variant="light" className="p-0">
            <div className="iq-menu-bt align-self-center">
              <div className="wrapper-menu">
                <div className="main-circle">
                  <i className="ri-more-fill"></i>
                </div>
                <div className="hover-circle">
                  <i className="ri-more-2-fill" onClick={minisidebar}></i>
                </div>
              </div>
            </div>
            <ul className="navbar-nav ms-auto navbar-list">
              <li className="nav-item iq-full-screen">
                <div to="#" className="iq-waves-effect" id="btnFullscreen">
                  <Fullscreen />
                </div>
              </li>
            </ul>

            <ul className="navbar-list">
              <Dropdown as="li">
                <Dropdown.Toggle
                  as={CustomToggle}
                  variant="search-toggle iq-waves-effect d-flex align-items-center"
                >
                  <h6 className="mb-0 line-height">{username}</h6>
                </Dropdown.Toggle>
                <Dropdown.Menu className="iq-sub-dropdown iq-user-dropdown">
                  <div className="iq-card shadow-none m-0">
                    <div className="iq-card-body p-0 ">
                      <div className="bg-primary p-3">
                        <h5 className="mb-0 text-white line-height">
                          Hello {username}
                        </h5>
                      </div>
                      <div className="d-inline-block w-100 text-center p-3">
                        <Link
                          className="bg-primary iq-sign-btn"
                          onClick={logout}
                          role="button"
                        >
                          Sign out<i className="ri-login-box-line ml-2"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </ul>
          </Navbar>
        </div>
      </div>
    </Fragment >
  );
});

Header.displayName = "Header";
export default Header;