import React, {
  useEffect,
  Fragment,
  useState
} from "react";
import {
  Button,
  Col,
  Form,
  FormControl,
  FormLabel,
  Row,
} from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { apiRequest } from "../../../api/apiUtils";
import { fetchSetting } from "../../../api/fetchLogo";

const renderInput = (inputs, handleChange, defaultValue) => {
  return (
    <FormControl
      type={inputs.field_type}
      id={inputs.field_name}
      name={inputs.field_name}
      className={`form-control validationCustom01 col-${inputs.properties.field_width} ${inputs.properties.class}`}
      required={inputs.properties.required}
      value={defaultValue || ''}
      onChange={(e) => handleChange(inputs.field_name, e.target.value)}
    />
  )
}

const FormLogin = ({ templateDb, dataDb, templateKey, fetchData }) => {
  //form validation
  const [template, setTemplate] = useState({});
  const [formValues, setFormValues] = useState({});
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();

  const handleChange = (fieldName, value) => {
    const lowercase = value.toLowerCase();
    setFormValues((prevValues) => ({
      ...prevValues,
      // [fieldName]: value,
      [fieldName]: fieldName === 'username' ? lowercase : value
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      await valueSubmit();
      console.log("value submitted");
      if (localStorage.getItem('username')) {
        await fetchSetting();
        console.log("its time to navigate")
        if (template.navigate)
          navigate(`/${template.navigate}`, { replace: true });
        else
          navigate("/calendar");
      }
    }
    setValidated(true);
  }

  useEffect(() => {
    const fetchInfo = async () => {
      await fetchTemplate();
    }
    fetchInfo();
  }, []);

  const fetchTemplate = async () => {
    try {
      const response = await apiRequest(`${templateDb}/get/${templateKey}`, 'GET', null, { 'Content-Type': 'application/json' });
      console.log(response.data[0]);
      setTemplate(response.data[0]);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const [errorMessage, setErrorMessage] = useState("");

  const valueSubmit = async () => {
    console.log(formValues);
    try {
      const result = await apiRequest(`${dataDb}/login`, 'POST', formValues, { 'Content-Type': 'application/json' });
      console.log(result.data.token);
      localStorage.setItem('token', result.data.token);
      localStorage.setItem('username', formValues.username);
      localStorage.setItem('userRole', result.data.role);
      console.log('userRole', result.data.role);
      await fetchData();
    } catch (error) {
      console.error('Error fetching data:', error);
      setErrorMessage("Username and/or password doesn’t match.");
      // setValidated(false);
    }
  };

  return (
    <Fragment>
      <div className="iq-card">
        <div className="iq-card-header d-flex justify-content-between">
          <div className="iq-header-title w-100">
            {
              template.title ? (
                <h4 className="card-title text-center fs-2">{template.title}</h4>
              ) : (<></>)
            }
          </div>
        </div>
        <div className="iq-card-body">
          {
            template.desc ? (
              <p>{template.desc}</p>
            ) : (<></>)
          }
          <Form
            className="needs-validation"
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
          >
            <Row>
              {
                template.fields?.map(inputs => {
                  return (
                    <Col className={`mb-3 col-${inputs.properties.field_width}`}>
                      <Form.Group className='form-group'>
                        <FormLabel
                          htmlFor={inputs.field_name}
                          className="mb-0 fs-6"
                        >
                          {inputs.field_label}
                        </FormLabel>
                        {renderInput(inputs, handleChange, formValues[inputs.field_name])}
                        {
                          inputs.properties.required === true ? (
                            <>
                              <div className="valid-feedback">Looks good!</div>
                              <div className="invalid-feedback">Please provide a valid {inputs.field_label}</div>
                            </>
                          ) : (<></>)
                        }
                      </Form.Group>
                    </Col>
                  )
                })
              }
            </Row>

            {
              template.Button?.map(inputs => {
                return (
                  <Button
                    variant="primary"
                    className={`btn btn-primary mb-3 me-2 col-${inputs.properties.field_width} fs-6`}
                    type={inputs.field_type}
                  >
                    {inputs.field_label}
                  </Button>
                )
              })
            }
            {errorMessage ? (
              <p className="text-danger text-center font-weight-bold">
                <i className="ri-error-warning-line fs-5"> </i>
                {errorMessage}
              </p>
            ) : (<></>)}
          </Form>
        </div>
      </div>
    </Fragment>
  );
};

export default FormLogin;
