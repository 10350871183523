import React from 'react'
import { useParams } from "react-router-dom"
import FormValidation from '../../../../views/modules/forms/form-validation'

export default function VisitForm(props) {
    const params = useParams();
    const values = {};
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams) {
        values['PatientName'] = queryParams.get('PatientName');
        values['PatientId'] = queryParams.get('PatientId');
    }

    return (
        <div>
            {
                params.id ? (
                    <FormValidation templateDb={props.block.templateDb} dataDb={props.block.dataDb} templateKey={props.block.templateKey} dataId={params.id} />
                ) : (
                    queryParams ? (
                        <FormValidation templateDb={props.block.templateDb} dataDb={props.block.dataDb} templateKey={props.block.templateKey} values={values} />
                    ) :
                        (
                            <FormValidation templateDb={props.block.templateDb} dataDb={props.block.dataDb} templateKey={props.block.templateKey} />
                        )
                )
            }
        </div>
    )
}
