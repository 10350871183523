import "./style.css";
import { Scheduler } from "@aldabil/react-scheduler";
import { ProcessedEvent, EventActions } from "@aldabil/react-scheduler/types";
import { useState, useEffect } from "react";
import { apiRequest } from "../../../api/apiUtils";
import { Typography } from "@mui/material";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Button, Col, Row } from "react-bootstrap";
import dateFormat from "dateformat";
import LoadingSpinner from "../extra-pages/loading";
import AppointmentForm from "./AppointmentForm";

export default function App() {
  // const [formValues, setFormValues] = useState({});
  let token = localStorage.getItem("token");
  const [events, setEvents] = useState([]);
  const [resources, setResources] = useState([]);
  const [patients, setPatients] = useState([]);
  let navigate = useNavigate();

  const handleSubmit = async (
    event: ProcessedEvent,
    action: EventActions
  ): Promise<ProcessedEvent> => {
    console.log(action, event);
    try {
      const patient = patients.find((p) => p._id === event.PatientId);
      const patientName = patient ? patient.PatientName : "";
      const doctor = resources.find((p) => p._id === event.DoctorId);
      const doctorName = doctor ? doctor.DoctorName : "";
      let updatedEvent = {
        ...event,
        title: patientName,
        PatientName: patientName,
        DoctorName: doctorName,
      };
      if (action === "create") {
        updatedEvent.event_id = Math.random();
        await valueSubmit(updatedEvent);
        return updatedEvent;
      } else if (action === "edit") {
        return await editEvent(event, updatedEvent);
      }
    } catch {
      throw new Error("Unsupported action");
    }
  };

  const editEvent = async (
    event: ProcessedEvent,
    updatedEvent
  ): Promise<ProcessedEvent> => {
    try {
      console.log(event);
      const id = event.event_id;
      console.log(`Request at : api/2_apt/post/${event.event_id}`);
      const response = await apiRequest(
        `2_apt/post/${id}`,
        "POST",
        updatedEvent,
        { "Content-Type": "application/json", Authorization: token }
      );
      await fetchEvents();
      const updatedEventData = response.data;
      console.log("Event updated:", updatedEventData);
      return updatedEventData;
    } catch (error) {
      console.error("Error updating event:", error);
      if (error.message === "Invalid Token") {
        console.log("error called");
        navigate("/sign-in?token=Invalid-Token");
      }
    }
  };

  const valueSubmit = async (event: ProcessedEvent) => {
    console.log("Submitting event to database");

    console.log(event);
    try {
      const result = await apiRequest(`2_apt/post/`, "POST", event, {
        "Content-Type": "application/json",
        Authorization: token,
      });
      await fetchEvents();
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.message === "Invalid Token") {
        console.log("error called");
        navigate("/sign-in?token=Invalid-Token");
      }
    }
  };

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      await fetchResources();
      await fetchPatients();
      await fetchEvents();
      setIsLoading(false);
    };
    fetchData();
  }, []);

  const fetchResources = async () => {
    try {
      const response = await apiRequest(`doctor/get`, "GET", null, {
        "Content-Type": "application/json",
        Authorization: token,
      });
      const eventsArray = response?.data;
      // console.log(typeof eventsArray[0]["_id"]);
      if (Array.isArray(eventsArray)) {
        const updatedResources = eventsArray.map((event) => ({
          ...event,
          DoctorId: event["_id"],
        }));
        setResources(updatedResources);
      }
    } catch (error) {
      console.error("Error fetching events:", error);
      if (error.message === "Invalid Token") {
        console.log("error called");
        navigate("/sign-in?token=Invalid-Token");
      }
    }
  };

  const fetchPatients = async () => {
    try {
      const response = await apiRequest(`patient/get`, "GET", null, {
        "Content-Type": "application/json",
        Authorization: token,
      });
      const eventsArray = response.data;
      console.log(eventsArray);
      setPatients(eventsArray);
    } catch (error) {
      console.error("Error fetching events:", error);
      if (error.message === "Invalid Token") {
        console.log("error called");
        navigate("/sign-in?token=Invalid-Token");
      }
    }
  };

  const handleDelete = async (
    deletedId: string | number
  ): Promise<string | number | void> => {
    try {
      let baseURL = process.env.REACT_APP_BACKEND_API_BASE_URL;
      let hostname = window.location.hostname;
      let subdomain = hostname.split(".")[0];
      const response = await axios.delete(
        `${baseURL}${subdomain}/2_apt/delete/${deletedId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      // const response = await apiRequest(
      //     `2_apt/delete/${deletedId}`,
      //     "DELETE",
      //     null,
      //     { "Content-Type": "application/json", Authorization: token }
      // );
      console.log("Event deleted successfully", response);
      // Optionally, you might want to update the state to reflect the deletion
      setEvents(events.filter((event) => event.event_id !== deletedId));
      return deletedId;
    } catch (error) {
      console.error("Error deleting event:", error);
      if (error.message === "Invalid Token") {
        console.log("error called");
        navigate("/sign-in?token=Invalid-Token");
      }
    }
  };

  const fetchEvents = async () => {
    try {
      const response = await apiRequest(`2_apt/get`, "GET", null, {
        "Content-Type": "application/json",
        Authorization: token,
      });
      const eventsArray = response.data;
      if (eventsArray.length > 0) {
        console.log(eventsArray[0]);
        const eventsWithDates = eventsArray?.map((event) => ({
          ...event,
          start: new Date(event.start),
          end: new Date(event.end),
        }));
        console.log(eventsWithDates[0]);
        setEvents(eventsWithDates);
      }
    } catch (error) {
      console.error("Error fetching events:", error);
      if (error.message === "Invalid Token") {
        console.log("error called");
        navigate("/sign-in?token=Invalid-Token");
      }
    }
  };
  // let formData = new FormData();
  const VisitButton = async (event) => {
    console.log(event);
    try {
      let visit;
      if (event.visitId) {
        visit = event.visitId;
      } else {
        let formData = {
          PatientName: event.PatientName,
          PatientId: event.PatientId,
          DoctorName: event.DoctorName,
          DoctorId: event.DoctorId,
          date_of_appointment: dateFormat(new Date(), "yyyy-mm-dd"),
          visit_time: dateFormat(new Date(), "HH:MM"),
          event_id: event.event_id,
        };

        let result = await apiRequest(`visit/post`, "POST", formData, {
          "Content-Type": "multipart/form-data",
          Authorization: token,
        });

        visit = result.data.insertedId;

        const response = await apiRequest(
          `2_apt/post/${event.event_id}`,
          "POST",
          {
            visitId: visit,
          },
          { "Content-Type": "application/json", Authorization: token }
        );
      }

      const queryParams = new URLSearchParams();

      console.log(event.PatientId);
      queryParams.append("PatientId", event.PatientId);
      queryParams.append("VisitId", visit);

      const queryString = queryParams.toString();
      navigate(`/visit?${queryString}`, { replace: true });
    } catch (error) {
      console.error("Error fetching events:", error);
      if (error.message === "Invalid Token") {
        console.log("error called");
        navigate("/sign-in?token=Invalid-Token");
      }
    }
  };

  const [showAppointmentForm, setShowAppointmentForm] = useState(false);

  if (isLoading) {
    return <LoadingSpinner />;
  }
  return (
    <div className="iq-card">
      <div className="iq-card-body">
        <Row>
          <span className="table-add float-end mb-1 me-2">
            <Button
              size='sm'
              variant='primary'
              className="btn btn-primary mb-3 me-2 p-2"
              onClick={() => setShowAppointmentForm(true)}
            >
              Book Appointment
            </Button>
            {showAppointmentForm && (
              <>
                <div
                  className="modal-backdrop"
                  onClick={() =>
                    setShowAppointmentForm(false)
                  }
                />
                <AppointmentForm
                  onClose={() =>
                    setShowAppointmentForm(false)
                  }
                />
              </>
            )}
          </span>
        </Row>

        <Scheduler
          dialogMaxWidth="sm"
          resources={resources}
          events={events?.map((event) => ({
            ...event,
            key: event.event_id,
            draggable: false,
          }))}
          view="day"
          translations={
            {
              navigation: {
                month: "Month",
                week: "Week",
                day: "Day",
                today: "Today",
                agenda: "Agenda"
              },
              form: {
                addTitle: "Add Appointment",
                editTitle: "Edit Appointment",
                confirm: "Confirm",
                delete: "Delete",
                cancel: "Cancel"
              },
              event: {
                title: "Title",
                start: "From",
                end: "To",
                allDay: "All Day"
              },
              validation: {
                required: "Required",
                invalidEmail: "Invalid Email",
                onlyNumbers: "Only Numbers Allowed",
                min: "Minimum {{min}} letters",
                max: "Maximum {{max}} letters"
              },
              moreEvents: "More...",
              noDataToDisplay: "No data to display",
              loading: "Loading..."
            }
          }
          resourceFields={{
            idField: "DoctorId",
            textField: "DoctorName",
            subTextField: "mobile",
            avatarField: "DoctorName",
            // colorField: "color"
          }}
          fields={[
            {
              name: "title",
              type: "hidden",
            },
            {
              name: "DoctorId",
              type: "select",
              default: resources[0]?._id,
              options: resources?.map((res) => {
                return {
                  id: res._id,
                  text: `${res.DoctorName} (${res.mobile})`,
                  value: res._id, //Should match "name" property
                };
              }),
              config: { label: "Doctor", required: true },
            },
            {
              name: "PatientId",
              type: "select",
              default: patients ? patients[0]?._id : "",
              options:
                patients && patients.length > 0
                  ? patients.map((res) => {
                    return {
                      id: res._id,
                      text: `${res.PatientName} (${res.mobile})`,
                      value: res._id, //Should match "name" property
                    };
                  })
                  : [],
              config: { label: "Patient", required: true },
            },
          ]}
          onConfirm={handleSubmit}
          onDelete={handleDelete}
          viewerExtraComponent={(fields, event) => {
            return (
              <div>
                {fields?.map((field, i) => {
                  if (field.name === "PatientId") {
                    const admin = field.options.find(
                      (fe) => fe.id === event.PatientId
                    );
                    return (
                      <Row>
                        <Col>
                          <Typography
                            key={i}
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                            color="textSecondary"
                            variant="caption"
                            noWrap
                          >
                            <PersonRoundedIcon />
                            <p className="ps-2">
                              {admin.text}
                            </p>
                          </Typography>
                        </Col>
                        <Col>
                          <button
                            className="visit-button btn-lg float-right"
                            onClick={() =>
                              VisitButton(event)
                            }
                          >
                            VISIT
                          </button>
                        </Col>
                      </Row>
                    );
                  } else {
                    return "";
                  }
                })}
              </div>
            );
          }}
        />
      </div>
    </div>
  );
}
