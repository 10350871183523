import 'rsuite/dist/rsuite.min.css';
import React, { useEffect, useState, Fragment } from "react";
import { useNavigate } from 'react-router-dom';
import { Table } from 'rsuite';
import { apiRequest } from "../../../../api/apiUtils";
import { Col, Row, Button } from "react-bootstrap";
import CurrencyFormat from 'react-currency-format';
import {
  EditableCell,
  ActionCell,
  fetchData,
  addNewRow,
  calculateTableHeight
} from "../../../../views/modules/table/table_function"

let token;
const { Column, HeaderCell, Cell } = Table;

let currency_format = JSON.parse(localStorage.getItem("currency"));

const generateDefaultData = () => {
  return {
    id: Math.random(),
    date: new Date().toISOString().split('T')[0],
    status: 'EDIT'
  };
};

export default function Payment({ billChanged, templateDb, dataDb, templateKey, dataId }) {
  const [tableValues, setTableValues] = useState({});
  const [PaymentFormat, setPaymentFormat] = useState({});
  const [paymentData, setPaymentData] = useState({});
  const [payment_data, setPayment_Data] = useState([]);

  const [paidAmount, setPaidAmoint] = useState(0);
  const [dueAmount, setDueAmount] = useState(0);
  const [changeAction, setChangeAction] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  let navigate = useNavigate();
  token = localStorage.getItem("token")

  const stateVariable = {
    dueAmount,
    paidAmount
  }

  useEffect(() => {
    const fetchInfo = async () => {
      setPaymentFormat(await fetchData(templateDb, templateKey, token, navigate));
      if (dataId) {
        setTableValues(await fetchData(dataDb, dataId, token, navigate));
      }
    }
    fetchInfo();
  }, [billChanged]);

  useEffect(() => {
    if (tableValues.bill) {
      setDueAmount(parseFloat(tableValues.bill?.total))
    }
    if (tableValues.payment) {
      // const initialData = tableValues.payment.payment?.map(item => ({ ...item, status: 'SAVE' }));
      if (tableValues.payment.payment_items && Object.keys(tableValues.payment.payment_items).length > 0) {
        setPaymentData(tableValues.payment.payment_items);
        setPayment_Data(tableValues.payment.payment_items);
        calculateTotal();
      }
      else
        setPaymentData([generateDefaultData()])
    }
    else
      setPaymentData([generateDefaultData()]);
  }, [tableValues]);

  useEffect(() => {
    const fetchInfo = async () => {
      if (changeAction) {
        await handleSubmit();
        setChangeAction(false);
      }
    }
    fetchInfo();
  }, [paidAmount, dueAmount, changeAction]);

  const calculateTotal = () => {
    let totalPaidAmount = 0;
    if (payment_data && payment_data.length > 0)
      totalPaidAmount = (payment_data.filter(item => item.status === 'SAVE')).reduce((sum, item) => sum + (parseFloat(item.amount, 10) || 0), 0);
    let due_amount;
    if (tableValues.bill.total)
      due_amount = parseFloat(tableValues.bill.total) - parseFloat(totalPaidAmount)
    setPaidAmoint(totalPaidAmount);
    setDueAmount(due_amount);
  }

  const handleSubmit = async () => {
    calculateTotal();
    let payment = {};
    if (tableValues.payment) {
      payment = {
        ...tableValues.payment,
        payment_items: payment_data,
        paid_amount: paidAmount
      }
    }
    else {
      payment = {
        payment_items: payment_data,
        paid_amount: paidAmount
      };
    }
    console.log(payment);
    try {
      const response =
        await apiRequest(
          `${dataDb}/post/${dataId}`,
          'POST',
          { payment },
          {
            'Content-Type': 'multipart/form-data',
            'Authorization': token
          }
        );
      setChangeAction(true);
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error.message === 'Invalid Token') {
        console.log("error called");
        navigate('/sign-in?token=Invalid-Token');
      }
    }
  };

  if (!tableValues.bill) return null;

  return (
    <Fragment>
      <Row>
        <Col sm='12' >
          <div className="iq-card">
            {
              PaymentFormat.title ? (
                <div className="iq-card-header d-flex justify-content-between">
                  <div className="iq-header-title">
                    <h4 className="card-title">
                      {PaymentFormat.title}
                    </h4>
                  </div>
                </div>
              ) : (<></>)
            }
            <div className="iq-card-body">
              <Row>
                <Col className="text-end">
                  <span className="">
                    <Button
                      size='sm'
                      variant='primary'
                      className="btn btn-primary mb-1 me-2 p-2"
                      onClick={() => addNewRow(paymentData, setPaymentData, generateDefaultData)}>
                      <i className="ri-add-fill"></i>
                      Add Payment Row
                    </Button>
                  </span>
                </Col>
              </Row>
              <div id="table" className="table-editable">
                <div className="table-responsive-md w-100">
                  <Table height={calculateTableHeight(paymentData)} data={paymentData} >
                    {
                      PaymentFormat.fields?.map((inputs, index) => {
                        return (
                          <Column width={200} key={inputs.field_name}>
                            <HeaderCell className='text-center'>{inputs.field_label}</HeaderCell>
                            <EditableCell dataKey={inputs.field_name} inputs={inputs} dataState={paymentData} setDataState={setPaymentData} setError={setErrorMessage} height={550} />
                          </Column>
                        )
                      })
                    }
                    <Column>
                      <HeaderCell className='text-center'>Save</HeaderCell>
                      <ActionCell className='text-center' dataKey="save" handleSubmit={handleSubmit} generateDefaultData={generateDefaultData} dataState={paymentData} setDataState={setPaymentData} fetchedData={payment_data} setFetchedData={setPayment_Data} fields={PaymentFormat.fields} setError={setErrorMessage} />
                    </Column>
                    <Column>
                      <HeaderCell className='text-center'>Delete</HeaderCell>
                      <ActionCell className='text-center' dataKey="delete" handleSubmit={handleSubmit} dataState={paymentData} setDataState={setPaymentData} fetchedData={payment_data} setFetchedData={setPayment_Data} setError={setErrorMessage} />
                    </Column>
                  </Table>
                  <div className="text-center font-weight-bold text-danger">{errorMessage}</div>
                </div>
              </div>
              <Row>
                <Col md={3} className="text-right">
                  <table className="table">
                    <tbody>
                      {
                        PaymentFormat.display_fields?.map((inputs, index) => {
                          return (
                            <tr key={index}>
                              <td className='text-center'>
                                <b>{inputs.field_label}</b>
                              </td>
                              <td className='text-end'>
                                <CurrencyFormat
                                  displayType='text'
                                  value={stateVariable[inputs.field_value]}
                                  {...currency_format}
                                />
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </Fragment >
  );
};