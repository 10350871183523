import { apiRequest } from "./apiUtils";

const fetchImage = async (url) => {
   const storedImageUrl = sessionStorage.getItem('logo');
   if (storedImageUrl) {
      return storedImageUrl;
   } else {
      try {
         const response = await fetch(url);
         const blob = await response.blob();
         const imageUrl = URL.createObjectURL(blob);
         console.log(imageUrl)
         sessionStorage.setItem('logo', imageUrl);
         console.log(imageUrl)
         return imageUrl;
      } catch (error) {
         console.error("Failed to fetch image:", error);
         return null;
      }
   }
};

const fetchLogo = async () => {
   try {
      const response = await apiRequest('clinic/get/', 'GET', null, { 'Content-Type': 'multipart/form-data', });
      const clinicData = response.data[0];
      console.log(clinicData)
      // if (clinicData && !clinicData.result != 'No Content found') {
      if (clinicData && !clinicData.result && clinicData['0_ClinicLogo']) {
         const clinicLogoUrl = clinicData['0_ClinicLogo'];
         const baseURL = process.env.REACT_APP_BACKEND_API_BASE_URL;
         const fullLogoUrl = `${baseURL}${clinicLogoUrl}`;
         console.log(fullLogoUrl)
         const fetchedImageUrl = await fetchImage(fullLogoUrl);
         return fetchedImageUrl;
      }
      else
         return null;
   } catch (error) {
      console.error('Error fetching data:', error);
      return null
   }
};

const fetchSetting = async () => {
   let currency = {};
   let dateFormat;
   let timeFormat;
   try {
      const response = await apiRequest('setting/get/', 'GET', null, { 'Content-Type': 'application/json' });
      if (Array.isArray(response.data)) {
         currency["thousandSeparator"] = response.data[0]["thousandSeparator"]
         currency["thousandSpacing"] = response.data[0]["thousandSpacing"]
         currency["decimalSeparator"] = response.data[0]["decimalSeparator"]
         if (response.data[0]["currencyPosition"] === "left")
            currency["prefix"] = response.data[0]["currencySymbol"]
         else
            currency["suffix"] = response.data[0]["currencySymbol"]
         dateFormat = response.data[0]["date_format"];
         timeFormat = response.data[0]["time_format"];
      }
      else {
         console.log("clinic not found")
         currency = {
            "prefix": "₹",
            "thousandSeparator": ",",
            "decimalSeparator": ".",
            "thousandSpacing": "3"
         }
         dateFormat = "dd/mm/yyyy";
         timeFormat = "HH:MM";
      }
   }
   catch {
      console.log("clinic not found")
      currency = {
         "prefix": "₹",
         "thousandSeparator": ",",
         "decimalSeparator": ".",
         "thousandSpacing": "3"
      }
      dateFormat = "dd/mm/yyyy";
      timeFormat = "HH:MM";
   }
   localStorage.setItem('currency', JSON.stringify(currency));
   localStorage.setItem('dateFormat', dateFormat);
   localStorage.setItem('timeFormat', timeFormat);
}

export {
   fetchLogo,
   fetchSetting
};