import { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { Button } from "react-bootstrap";
import { apiRequest } from '../../../api/apiUtils';
import { Default } from '../../../prescription/default';
import LoadingSpinner from '../../../views/modules/extra-pages/loading';

const PrintPresciption = ({ visit, billChanged }) => {
  const printRef = useRef();
  const [clinicInfo, setCiniInfo] = useState({});
  const [tableTemplate, setTableTemplate] = useState({});
  const [data, setData] = useState({});
  let token = localStorage.getItem("token")
  let navigate = useNavigate();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  useEffect(() => {
    const fetchInfo = async () => {
      await fetchTemplate();
      await fetchClinicInfo();
      await fetchData();
    }
    fetchInfo();
  }, [])

  const fetchClinicInfo = async () => {
    try {
      const response = await apiRequest(`clinic/get`, 'GET', null, {
        'Content-Type': 'application/json',
        'Authorization': token
      });
      // console.log(response.data[0]);
      setCiniInfo(response.data[0]);
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error.message === 'Invalid Token') {
        console.log("error called");
        navigate('/sign-in?token=Invalid-Token');
      }
    }
  }

  const fetchData = async () => {
    try {
      const response = await apiRequest(
        `invoice_view/get/${visit}`,
        'GET',
        null,
        {
          'Content-Type': 'multipart/form-data',
          'Authorization': token
        }
      );
      // console.log(response.data[0]);
      setData(response.data[0]);
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error.message === 'Invalid Token') {
        console.log("error called");
        navigate('/sign-in?token=Invalid-Token');
      }
    }
  };

  const fetchTemplate = async () => {
    try {
      const response = await apiRequest(
        `0_table_template/get/prescription`,
        'GET',
        null,
        {
          'Content-Type': 'multipart/form-data',
          'Authorization': token
        }
      );
      // console.log(response.data[0]);
      setTableTemplate(response.data[0]);
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error.message === 'Invalid Token') {
        console.log("error called");
        navigate('/sign-in?token=Invalid-Token');
      }
    }
  };

  if (Object.keys(clinicInfo).length !== 0 && Object.keys(data).length !== 0 && Object.keys(tableTemplate).length !== 0) {
    return (
      <div>
        <Default ref={printRef} data={data} tableTemplate={tableTemplate} clinicInfo={clinicInfo} />
        <Button onClick={handlePrint}>Print Prescription</Button>
      </div>
    );
  };
  return <LoadingSpinner />;
};

export default PrintPresciption;