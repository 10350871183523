import React, { useEffect, Fragment, useState } from "react";
import { Button, Col, Form, FormControl, FormLabel, FormSelect, Row } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { apiRequest } from "../../../api/apiUtils";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CurrencyFormat from 'react-currency-format';
import { fetchSetting } from "../../../api/fetchLogo";
import LoadingSpinner from "../extra-pages/loading";
import Chip from '@material-ui/core/Chip';

let token, clinic_id;
let filter = createFilterOptions();
let currency_format = JSON.parse(localStorage.getItem("currency"));

const RenderInput = ({ inputs, handleChange, handleImage, initialValue, dataId }) => {
  let inputElement;
  switch (inputs.field_type) {
    case "select":
      inputElement =
        <FormSelect
          id={inputs.field_name}
          className={`form-select ${inputs.properties.class}`}
          name={inputs.field_name}
          required={inputs.properties.required}
          value={initialValue || ''}
          onChange={(e) => handleChange(e.target, [[[inputs.field_name], "value"]])}
        // onChange={(e) => handleChange(inputs.field_options[e.target.value], [[[inputs.field_name], "value"], ...inputs.fields_dependent])}
        >
          <option value="">Select {inputs.field_label}</option>
          {
            inputs.field_options?.map(options => {
              return (
                <option value={options.value}>{options.label}</option>
              )
            })
          }
        </FormSelect>;
      break;
    case "autocomplete":
      inputElement =
        <Autocomplete
          id={inputs.field_name}
          multiple={inputs.properties?.multi_select}
          freeSolo={inputs.properties?.type_select ? true : false}
          options={inputs.field_options}
          handleHomeEndKeys
          filterSelectedOptions={inputs.properties?.multi_select ? true : false}
          // value={inputs.field_options.find(option => option.value === initialValue) || { initialValue } || {}}
          value={
            (() => {
              if (inputs.properties?.multi_select) {
                if (Array.isArray(initialValue) && initialValue.length > 0) {
                  console.log(initialValue)
                  let matchedOptions = [], newOptions = [];
                  initialValue.forEach(item => {
                    const matchingOption = inputs.field_options?.find(option => option.value === item);
                    if (matchingOption) {
                      matchedOptions.push(matchingOption);
                    } else {
                      newOptions.push({
                        value: item,
                        label: item
                      });
                    }
                  });
                  let combinedOptions = [...matchedOptions, ...newOptions];
                  console.log(matchedOptions, newOptions, combinedOptions);
                  return combinedOptions;
                }
                else
                  return [];
              }
              else {
                return inputs.field_options?.find(option => option.value === initialValue) || { initialValue } || {}
              }
            })()
          }
          renderTags={(value, getTagProps) =>
            value.map((option, index) => {
              // const red = Math.floor(Math.random() * 256);
              // const green = Math.floor(Math.random() * 256);
              // const blue = Math.floor(Math.random() * 256);
              // const opacity = 0.5;
              return (
                <Chip
                  variant="outlined"
                  label={option.label}
                  {...getTagProps({ index })}
                // style={{
                //   backgroundColor: `rgba(${red}, ${green}, ${blue}, ${opacity})`,
                //   color: '#000',
                //   fontWeight: 500,
                // }}
                />
              );
            })
          }
          onChange={(e, newValue) => {
            let value = newValue;
            if (Array.isArray(newValue) && inputs.properties?.multi_select) {
              value = newValue.map(item => {
                if (typeof item === 'string') {
                  const matchingOption = inputs.field_options?.find(option => option.label === item);
                  return matchingOption || { value: item, label: item };
                }
                return item;
              });
              value = value.filter((item, index, self) => {
                return index === self.findIndex(t => t.value === item.value);
              });
            }
            console.log(value)
            if (inputs?.fields_dependent) {
              console.log(inputs.fields_dependent);
              if (value) {
                handleChange(value, [[[inputs.field_name], "value"], ...inputs.fields_dependent])
              }
              else {
                handleChange(null, [inputs.field_name, ...inputs.fields_dependent]);
              }
            }
            else {
              if (value) {
                handleChange(value, [[[inputs.field_name], "value"]])
              }
              else {
                handleChange(null, [inputs.field_name]);
              }
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            const { inputValue } = params;
            const isExisting = options.some((option) => inputValue === option.value);
            if (inputValue !== '' && !isExisting && inputs.properties?.type_select) {
              filtered.push({
                inputValue,
                value: inputValue,
                label: inputValue,
              });
            }
            return filtered;
          }}
          getOptionLabel={(option) => {
            if (inputs.properties?.type_select) {
              // Value selected with enter, right from the input
              if (typeof option === 'string')
                return option;
              // Add option created dynamically
              if (option.inputValue)
                return option.inputValue;
              // Regular option
              return option.label;
            }
            else
              return option.label || "";
          }}
          required={inputs.properties?.required}
          size="samll"
          renderInput={(params) => (
            inputs.properties.multi_select ?
              <TextField
                {...params}
                variant="outlined"
                fullWidth
                size="samll"
                required={inputs.properties?.required}
              />
              :
              <TextField
                {...params}
                variant="standard"
                fullWidth
                required={inputs.properties?.required}
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                  classes: {
                    root: 'form-control',
                    input: 'form-control',
                  },
                }}
              />
          )}
          ListboxProps={{
            style: { fontSize: 'smaller' },
          }}
        />;
      break;
    case "radio":
      inputElement =
        <div className="custom-control-inline">
          {
            inputs.field_options.map(options => {
              return (
                <Form.Check
                  className={`custom-control custom-${inputs.field_type}`}
                  type={inputs.field_type}
                  id={options.value}
                  value={options.value}
                  name={inputs.field_name}
                  onChange={(e) => handleChange(e.target, [[[inputs.field_name], "value"]])}
                  required={inputs.properties.required}
                  checked={initialValue === options.value ? true : false}
                  label={options.label}
                />
              )
            })
          }
        </div>;
      break;
    case "textarea":
      inputElement =
        <FormControl
          as={inputs.field_type}
          id={inputs.field_name}
          name={inputs.field_name}
          className={`form-control validationCustom01 ${inputs.properties.class}`}
          required={inputs.properties.required}
          rows={inputs.properties.rows || 5}
          value={initialValue || ''}
          style={{ resize: "none" }}
          onChange={(e) => handleChange(e.target, [[[inputs.field_name], "value"]])}
        />;
      break;
    case "file":
      inputElement =
        <FormControl
          type={inputs.field_type}
          id={inputs.field_name}
          name={inputs.field_name}
          className={`form-control validationCustom01 ${inputs.properties.class}`}
          required={clinic_id ? false : inputs.properties.required}
          onChange={(e) => handleImage(inputs.field_name, e)}
        />;
      break;
    case "Currency":
      inputElement =
        <CurrencyFormat
          displayType={'input'}
          id={inputs.field_name}
          name={inputs.field_name}
          className={`form-control validationCustom01 ${inputs.properties.class}`}
          required={inputs.properties.required}
          {...currency_format}
          value={initialValue || ''}
          onValueChange={(values) => {
            const { formattedValue, value } = values;
            handleChange(values, [[[inputs.field_name], "value"]])
          }
          }
        />;
      break;
    // case "date":
    //   inputElement =
    //     <div>
    //       <DatePicker
    //         type={inputs.field_type}
    //         // className={`rs-input ${inputs.properties.class}`}
    //         // value={new Date(initialValue) || ''}
    //         value={initialValue ? new Date(initialValue) : ''}
    //         format="dd/MMM/yyyy"
    //         oneTap
    //         cleanable={false}
    //         onChange={e => {
    //           console.log(e)
    //           handleChange(e, [[[inputs.field_name], "value"]], "date")
    //         }}
    //       // }}
    //       />
    //     </div>
    //   break;
    default:
      inputElement =
        <FormControl
          type={inputs.field_type}
          id={inputs.field_name}
          name={inputs.field_name}
          className={`form-control validationCustom01 ${inputs.properties.class}`}
          required={inputs.properties.required}
          value={initialValue || ''}
          onChange={(e) => handleChange(e.target, [[[inputs.field_name], "value"]])}
        />;
      break;
  }
  return (
    <>
      {inputElement}
      {
        inputs.properties.required === true ? (
          <>
            <div className="valid-feedback">Looks good!</div>
            <div className="invalid-feedback">Please provide a valid {inputs.field_label}</div>
          </>
        ) : (<></>)
      }
    </>
  )
}

const FormValidation = ({ templateDb, dataDb, templateKey, dataId, values }) => {
  const [template, setTemplate] = useState({});
  const [formValues, setFormValues] = useState({});
  const [validated, setValidated] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  token = localStorage.getItem("token")

  // const handleChange = (value, elements, type) => {
  const handleChange = (value, elements) => {
    const updatedValues = elements.reduce((accumulator, key) => {
      console.log(key, value);
      if (value !== null) {
        if (Array.isArray(key)) {
          console.log(value);
          // if (type === "date") {
          //   accumulator[key[0]] = value;
          // }
          // else {
          let fieldValue;
          if (Array.isArray(value)) {
            if (value.length > 0) {
              fieldValue = value.map(obj => obj.value);
            }
            if (value.length === 0)
              fieldValue = []
          }
          else
            fieldValue = value[key[1]];
          if (key[1] === 'files')
            accumulator[key[0]] = fieldValue[0]
          else
            accumulator[key[0]] = fieldValue;
        }
        else {
          if (Array.isArray(value))
            accumulator[key] = value.map(obj => obj.value);
          else
            accumulator[key] = value[key];
        }
      }
      else {
        accumulator[key] = null
      }
      return accumulator;
    }, {});
    console.log(updatedValues)
    setFormValues((prevValues) => {
      const newValues = { ...prevValues };
      for (const key in updatedValues) {
        if (updatedValues[key] === null) {
          delete newValues[key];
        }
        if (Array.isArray(updatedValues[key]) && updatedValues[key].length === 0) {
          // delete updatedValues[key]
          updatedValues[key] = "";
          // delete newValues[key];
        }
      }
      console.log({ ...newValues, ...updatedValues })
      return { ...newValues, ...updatedValues };
    });
  };

  const uploadedImage = React.useRef(null);

  const handleImage = (fieldName, event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 5048576) {
        alert('File size should not exceed  1MB');
        event.target.value = '';
        return;
      }
      if (!file.type.startsWith('image/')) {
        alert('Please select an image file.');
        event.target.value = '';
        return;
      }
      console.log(fieldName, event.target)
      handleChange(event.target, [[[fieldName], "files"]]);
      const reader = new FileReader();
      reader.onload = (e) => {
        uploadedImage.current.src = e.target.result;
        uploadedImage.current.style.display = 'block';
      };
      reader.readAsDataURL(file);
    }
    console.log(file);
  }

  let formData = new FormData();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    console.log(form.checkValidity());
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    else {
      Object.keys(formValues).forEach((key) => {
        formData.set(key, formValues[key]);
      });
      console.log(formValues)
      valueSubmit();
      form.reset();
      if (uploadedImage.current) {
        uploadedImage.current.src = '';
        uploadedImage.current.style.display = 'none';
      }
    }
    setValidated(true);
  }

  useEffect(() => {
    const fetchInfo = async () => {
      await fetchTemplate();
      if (dataId || templateKey === 'clinic' || templateKey === 'setting') {
        await fetchData();
      }
      if (values) {
        console.log(values)
        setFormValues((prevValues) => ({
          ...prevValues,
          ...values
        }));
      }
      setIsLoading(false);
    }
    fetchInfo();
  }, [templateKey, dataId, values])

  //to update image url to display logo
  useEffect(() => {
    const fetchInfo = async () => {
      if (templateKey === 'clinic') {
        if (submitted) {
          const logoUrl = sessionStorage.getItem('logo');
          console.log(logoUrl)
          sessionStorage.removeItem('logo');
          caches.delete(logoUrl);
          window.location.reload();
        }
      }
      else if (templateKey == 'setting') {
        if (submitted) {
          localStorage.removeItem('currency');
          localStorage.removeItem('dateFormat');
          localStorage.removeItem('timeFormat');
          await fetchSetting();
          window.location.reload();
        }
      }
      else;
    }
    fetchInfo();
  }, [submitted]);

  const fetchTemplate = async () => {
    try {
      console.log("token", token);
      const response = await apiRequest(
        `${templateDb}/get/${templateKey}`,
        'GET',
        null,
        {
          'Content-Type': 'multipart/form-data',
          'Authorization': token
        }
      );
      console.log(response.data[0]);
      setTemplate(response.data[0]);
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error.message === 'Invalid Token') {
        console.log("error called");
        navigate('/sign-in?token=Invalid-Token');
      }
    }
  };

  const fetchData = async () => {
    try {
      let url = dataId ? `${dataDb}/get/${dataId}` : `${dataDb}/get`;
      setFormValues({})
      formData = new FormData();
      const response = await apiRequest(
        url,
        'GET',
        null,
        {
          'Content-Type': 'multipart/form-data',
          'Authorization': token
        }
      );
      console.log(response.data[0]);
      if (response.data[0]) {
        clinic_id = response.data[0]._id;
        console.log("Set id : ", dataId);
        for (const property in response.data[0]) {
          if (property.startsWith('0')) {
            const baseURL = process.env.REACT_APP_BACKEND_API_BASE_URL;
            const url = response.data[0][property];
            const fullURL = `${baseURL}${url}`;
            console.log(url);
            console.log(fullURL);
            const fetchedImage = await fetch(fullURL, {
              method: 'GET',
              headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': token
              }
            });
            const fetchedImageData = await fetchedImage.blob();
            const reader = new FileReader();
            reader.readAsDataURL(fetchedImageData);
            reader.onloadend = () => {
              uploadedImage.current.src = reader.result;
              uploadedImage.current.style.display = 'block';
            };
            formData.set(property, fullURL);
          }
          setFormValues(prevValues => ({
            ...prevValues,
            [property]: response.data[0][property]
          }));
        }
        console.log(formValues);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error.message === 'Invalid Token') {
        console.log("error called");
        navigate('/sign-in?token=Invalid-Token');
      }
    }
  };

  const valueSubmit = async () => {
    console.log(formValues);
    console.log("id : ", dataId);
    let visit;
    let result;
    let id = formValues['_id'];
    try {
      if (dataId || (id && templateKey === 'clinic') || (id && templateKey === 'setting')) {
        visit = formValues['_id'];
        delete formValues['_id'];
        console.log(formValues);
        result = await apiRequest(
          `${dataDb}/post/${visit}`,
          'POST',
          formValues,
          {
            'Content-Type': 'multipart/form-data',
            'Authorization': token
          }
        );
      }
      else {
        console.log("no id");
        if (templateKey === 'clinic')
          formData.set('billNumber', 0);
        result = await apiRequest(
          `${dataDb}/post`,
          'POST',
          formData,
          {
            'Content-Type': 'multipart/form-data',
            'Authorization': token
          }
        );
        console.log(result.data.insertedId);
        visit = result.data.insertedId;
        setFormValues({});
      }
      if (result)
        setSubmitted(true);
      if (template.navigate) {
        if (template.navigate === 'visit') {
          const queryParams = new URLSearchParams();

          console.log(formValues['PatientId']);
          queryParams.append('PatientId', formValues['PatientId']);
          queryParams.append('VisitId', visit);

          const queryString = queryParams.toString();
          navigate(`/${template.navigate}?${queryString}`, { replace: true });
        }
        else {
          navigate(`/${template.navigate}`, { replace: true });
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error.message === 'Invalid Token') {
        console.log("error called");
        navigate('/sign-in?token=Invalid-Token');
      }
    }
  };
  // const Name = "Dhrumi";
  // const htmlString = <div>Hello, <strong>{Name}</strong>!</div>;
  if (isLoading) {
    return <LoadingSpinner />;
  }
  return (
    <Fragment>
      <div className="iq-card">
        {
          template.title &&
          <div className="iq-card-header d-flex justify-content-between">
            <div className="iq-header-title w-100">
              <h4 className="card-title text-center fs-2">{template.title}</h4>
            </div>
          </div>
        }
        <div className="iq-card-body">
          {
            template.desc &&
            <p>{template.desc}</p>
          }
          <Form
            className="needs-validation"
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
          >
            <Row>
              {
                template.sections?.map(section => {
                  return (
                    <>
                      {
                        section.title && <h4 className="card-title ">{section.title}</h4>
                      }
                      {
                        section.fields?.map(inputs => {
                          return (
                            <>
                              <Col className={inputs.properties.size ? `mb-3 ${inputs.properties.size}` : `mb-3 col-12 col-md-6`}>
                                <Form.Group className='form-group'>
                                  <FormLabel
                                    htmlFor={inputs.field_name}
                                    className="mb-0 fs-6"
                                  >
                                    {inputs.field_label}
                                    {
                                      inputs?.properties?.required ? <span className="text-danger fw-bold"> *</span> : <></>
                                    }
                                  </FormLabel>
                                  <RenderInput inputs={inputs} handleChange={handleChange} handleImage={handleImage} initialValue={formValues[inputs.field_name]} dataId={dataId} />
                                </Form.Group>
                              </Col>
                              {
                                inputs.field_type === "file" &&
                                <Col className={inputs.properties.size ? `mb-3 ${inputs.properties.size} ` : `mb-3 col-12 col-md-6`}>
                                  <img
                                    ref={uploadedImage}
                                    alt="No Image Found"
                                    style={{ display: 'none', marginTop: '0px', width: '100px', height: '100px' }} />
                                </Col>
                              }
                            </>
                          )
                        })
                      }
                    </>
                  )
                })}
            </Row>
            {/* <Row>
              {htmlString} */}
            {/* <pre>{formValues["currency"]}</pre>
              <div
                dangerouslySetInnerHTML={{ __html: formValues["currency"] }}
              /> */}
            {/* </Row> */}
            {
              template.Button?.map(inputs => {
                return (
                  <Button
                    variant="primary"
                    className={inputs.properties.size ? `btn btn-primary mb-3 me-2 ${inputs.properties.size} fs-6` : `btn btn-primary mb-3 me-2 col-12 col-md-6 fs-6`}
                    // className={`btn btn-primary mb-3 me-2 col-12 col-md-6 col-lg-${inputs.properties.field_width} fs-6`}
                    type={inputs.field_type}
                  >
                    {inputs.field_label}
                  </Button>
                )
              })
            }
          </Form>
        </div>
      </div>
    </Fragment>
  );
};

export default FormValidation;
