import React, { useEffect, useState, Fragment } from "react";
import { Table } from 'rsuite';
import { Col, Row, Form, Button, FormSelect } from 'react-bootstrap'
import 'rsuite/dist/rsuite.min.css';
import { useNavigate } from 'react-router-dom';
import { apiRequest } from "../../../api/apiUtils";
import LoadingSpinner from "../extra-pages/loading";
// import axios from 'axios';
import { EditableCell } from "./table_function";

const { Column, HeaderCell, Cell } = Table;

export default function DataTable({ templateDb, dataDb, templateKey, dataId }) {
  const [data, setData] = useState([]);
  const [TableForamt, setTableForamt] = useState({});
  const [sortColumn, setSortColumn] = useState();
  const [sortType, setSortType] = useState();
  const [loading, setLoading] = useState(false); //For data sorting
  const [isLoading, setIsLoading] = useState(true);//For page loading
  const [searchKeywords, setSearchKeywords] = useState({});
  const [selectedColumn, setSelectedColumn] = useState(null);
  const navigate = useNavigate();
  let token = localStorage.getItem("token");

  useEffect(() => {
    const fetchInfo = async () => {
      await fetchTemplate();
      await fetchData();
      setIsLoading(false);
    }
    fetchInfo();

  }, [templateKey, dataId]);

  const fetchTemplate = async () => {
    try {
      const response = await apiRequest(`${templateDb}/get/${templateKey}`, 'GET', null, { 'Content-Type': 'multipart/form-data', 'Authorization': token });
      console.log(response.data[0]);
      setTableForamt(response.data[0]);
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error.message === 'Invalid Token') {
        console.log("error called");
        navigate('/sign-in?token=Invalid-Token');
      }
    }
  };

  const fetchData = async () => {
    try {
      let response;
      if (dataId) {
        response = await apiRequest(`${dataDb}/get/${dataId}`, 'GET', null, { 'Content-Type': 'multipart/form-data', 'Authorization': token });
        console.log(response.data[0]["result"]);
        setData(response.data[0]["result"])
      }
      else {
        response = await apiRequest(`${dataDb}/get`, 'GET', null, { 'Content-Type': 'multipart/form-data', 'Authorization': token });
        console.log(response.data);
        setData(response.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error.message === 'Invalid Token') {
        console.log("error called");
        navigate('/sign-in?token=Invalid-Token');
      }
    }
  };

  const handleButtonAction = async (action, rowData) => {
    // console.log(action, id)
    switch (action) {
      case "edit": {
        console.log("here");
        // console.log(rowData._id);
        if (rowData)
          navigate(`/${templateKey}-form/${rowData._id}`, { replace: true });
        else if (dataId)
          navigate(`/${templateKey}-form/${dataId}`, { replace: true });
        else
          navigate(`/${templateKey}-form`, { replace: true });
        break;
      }
      case "delete": {
        console.log("here");
        console.log(rowData._id);
        console.log(`${dataDb}/delete/${rowData._id}`);
        try {
          // const response = await axios.delete(`http://localhost:5000/api/${dataDb}/delete/${rowData._id}`, {
          //   headers: {
          //     'Content-Type': 'multipart/form-data',
          //     'Authorization': token
          //   }
          // });
          const response = await apiRequest(`${dataDb}/delete/${rowData._id}`, 'DELETE', null, { 'Content-Type': 'multipart/form-data', 'Authorization': token });
          console.log('Delete operation successful', response);
          alert(`${templateKey} deleted`);
          fetchTemplate();
          fetchData();
        }
        catch (error) {
          console.error('Delete operation failed', error);
          if (error.message === 'Invalid Token') {
            console.log("error called");
            navigate('/sign-in?token=Invalid-Token');
          }
        }
        break;
      }
      case "visit": {
        console.log("here");
        console.log(rowData._id);
        const queryParams = new URLSearchParams();

        queryParams.append('PatientId', rowData._id);
        queryParams.append('PatientName', rowData['PatientName']);

        const queryString = queryParams.toString();
        console.log(queryString);

        navigate(`/visit-form?${queryString}`, { replace: true });
        break;
      }
      case "bill": {
        console.log(rowData)
        const queryParams = new URLSearchParams();

        queryParams.append('PatientId', rowData['PatientId']);
        queryParams.append('VisitId', rowData._id);

        const queryString = queryParams.toString();
        console.log(queryString);

        navigate(`/visit?${queryString}`, { replace: true });
        break;
      }
    }
  };


  const handleSortColumn = (sortColumn, sortType) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };

  const handleSearch = (value, dataKey) => {
    setSearchKeywords((prevKeywords) => ({
      ...prevKeywords,
      [selectedColumn]: value,
    }));
  };

  const filteredData = Array.isArray(data)
    ? data.filter((row) => {
      if (selectedColumn) {
        return searchKeywords[selectedColumn]
          //use "includes" to match substring
          ? String(row[selectedColumn]).toLowerCase().startsWith(searchKeywords[selectedColumn]?.toLowerCase())
          : true;
      } else {
        return Object.keys(searchKeywords).length === 0 ||
          Object.entries(searchKeywords).every(
            ([dataKey, keyword]) =>
              keyword === '' ? true : Object.values(row).some((value) => String(value).toLowerCase().startsWith(keyword.toLowerCase()))
          );
      }
    })
    : [];

  const sortedData = () => {
    if (sortColumn && sortType) {
      return filteredData.sort((a, b) => {
        let x = a[sortColumn];
        let y = b[sortColumn];
        if (typeof x === 'string') {
          x = x.charCodeAt();
        }
        if (typeof y === 'string') {
          y = y.charCodeAt();
        }
        return sortType === 'asc' ? x - y : y - x;
      });
    }
    return filteredData;
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }
  return (
    <Fragment>
      <Row>
        <Col sm='12' >
          <div className="iq-card">
            {
              TableForamt.title ? (
                <div className="iq-card-header d-flex justify-content-between">
                  <div className="iq-header-title">
                    <h4 className="card-title">
                      {TableForamt.title}
                    </h4>
                  </div>
                </div>
              ) : (<></>)
            }
            <div className="iq-card-body">
              <div id="table" className="table-editable">
                {
                  TableForamt.mode && TableForamt.mode === 'read_only' ? (<></>) : (
                    <span className="table-add float-end mb-3 me-2">
                      <Button
                        size='sm'
                        variant='primary'
                        className="btn btn-primary mb-3 me-2 p-2"
                        onClick={() => handleButtonAction("edit")}>
                        <i className="ri-add-fill"></i>
                        Add New {templateKey}
                      </Button>
                    </span>
                  )
                }
                <div className="table-responsive-md w-100">
                  {
                    TableForamt.mode && TableForamt.mode === 'read_only' ? (<></>) : (
                      <Row>
                        <Col md={3}>
                          <FormSelect
                            className="form-select"
                            value={selectedColumn}
                            onChange={(e) => setSelectedColumn(e.target.value)}                  >
                            <option value=''>Select Column</option>
                            {TableForamt.fields?.map((inputs) => (
                              <option key={inputs.field_name} value={inputs.field_name}>
                                {inputs.field_label}
                              </option>
                            ))}
                          </FormSelect>
                        </Col>
                        <Col md={3}>
                          <Form.Control
                            type="text"
                            placeholder="Search..."
                            value={searchKeywords[selectedColumn] || ''}
                            onChange={(e) => handleSearch(e.target.value, selectedColumn)}
                          />
                        </Col>
                      </Row>
                    )
                  }
                  <Table
                    height={TableForamt.height || 420}
                    width={"100%"}
                    data={sortedData()}
                    sortColumn={sortColumn}
                    sortType={sortType}
                    onSortColumn={handleSortColumn}
                    loading={loading}
                    onRowClick={rowData => {
                      console.log(rowData);
                    }}
                  >
                    {TableForamt.fields?.map(inputs => (
                      <Column key={inputs.field_name} width={200} sortable>
                        <HeaderCell>{inputs.field_label}</HeaderCell>
                        <EditableCell dataKey={inputs.field_name} inputs={inputs} />
                      </Column>
                    ))}
                    {TableForamt.Button?.map(button => (
                      <Column key={button.field_name} width={100}>
                        <HeaderCell>{button.field_label}</HeaderCell>
                        <Cell style={{ padding: '6px' }}>
                          {rowData => (
                            <Button
                              variant={button.properties.variant ? `${button.properties.variant}` : ''}
                              size='sm'
                              className={`${button.properties.class}`}
                              onClick={() => handleButtonAction(button.field_name, rowData)}
                            >
                              <i className={`${button.properties.symbol_class}`}></i>
                              {button.field_label}
                            </Button>
                          )}
                        </Cell>
                      </Column>
                    ))}
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};