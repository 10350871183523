// LoadingSpinner.js
import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

const LoadingSpinner = () => {
 return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(255, 255, 255, 0.3)',
      zIndex: 9999,
      flexDirection: 'column',
    }}>
      <Spinner animation="border" role="status" style={{ width: '3rem', height: '3rem', color: '#089bab', marginBottom: '1rem' }}>
        <span className="visually-hidden">Loading...</span>
      </Spinner>
      <span style={{ fontSize: '1.2rem', color: '#089bab' }}>Loading...</span>
    </div>
 );
};

export default LoadingSpinner;