import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PatientForm from './PatientForm';
import { apiRequest } from '../../../api/apiUtils';
import { Button, Form, FormGroup, FormLabel } from 'react-bootstrap';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

function AppointmentForm({ onClose }) {
  const [patientOption, setPatientOption] = useState([]);
  const [doctorOption, setDoctorOption] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState('');
  const [selectedDoctor, setSelectedDoctor] = useState('');
  const [showPatientForm, setShowPatientForm] = useState(false);
  const [start, setstart] = useState(new Date());
  const [end, setend] = useState(new Date());
  let token = localStorage.getItem('token');
  let navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      await fetchPatients();
      await fetchDoctors();
    };
    fetchData();
  }, []);

  const fetchPatients = async () => {
    try {
      const response = await apiRequest('patient/get', 'GET', null, {
        'Content-Type': 'application/json',
        Authorization: token,
      });
      const eventsArray = response.data;

      for (const event of eventsArray) {
        event['label'] = `${event.PatientName} (${event.mobile})`;
        event['value'] = event._id;
      }
      setPatientOption(eventsArray);
    } catch (error) {
      console.error('Error fetching events:', error);
      if (error.message === 'Invalid Token') {
        navigate('/sign-in?token=Invalid-Token');
      }
    }
  };

  const fetchDoctors = async () => {
    try {
      const response = await apiRequest('doctor/get', 'GET', null, {
        'Content-Type': 'application/json',
        Authorization: token,
      });
      const eventsArray = response.data;

      for (const event of eventsArray) {
        event['label'] = `${event.DoctorName} (${event.mobile})`;
        event['value'] = event._id;
      }
      setDoctorOption(eventsArray);
    } catch (error) {
      console.error('Error fetching events:', error);
      if (error.message === 'Invalid Token') {
        navigate('/sign-in?token=Invalid-Token');
      }
    }
  };

  const handleChange = (value, elements) => {
    const updatedValues = elements.reduce((accumulator, key) => {
      if (value !== null) {
        if (Array.isArray(key)) {
          let fieldValue;
          if (Array.isArray(value)) {
            if (value.length > 0) {
              fieldValue = value.map(obj => obj.value);
            }
            if (value.length === 0) fieldValue = [];
          } else fieldValue = value[key[1]];
          if (key[1] === 'files') accumulator[key[0]] = fieldValue[0];
          else accumulator[key[0]] = fieldValue;
        } else {
          if (Array.isArray(value)) accumulator[key] = value.map(obj => obj.value);
          else accumulator[key] = value[key];
        }
      } else {
        accumulator[key] = null;
      }
      return accumulator;
    }, {});
    console.log(updatedValues);
  };

  const handleAddPatient = (newOption) => {
    console.log(newOption);
    setPatientOption([...patientOption, newOption]);
    setSelectedPatient(newOption);
    setShowPatientForm(false);
  };

  const handleBookAppointment = async () => {
    try {
      const appointmentData = {
        event_id: Math.random(),
        title: selectedPatient.PatientName,
        start,
        end,
        DoctorId: selectedDoctor._id,
        DoctorName: selectedDoctor.DoctorName,
        PatientId: selectedPatient._id,
        PatientName: selectedPatient.PatientName
      };

      const response = await apiRequest(
        '2_apt/post',
        'POST',
        appointmentData,
        { 'Content-Type': 'application/json', Authorization: token }
      );

      console.log('Appointment booked:', response.data);
      onClose(); // Close the popup on successful booking
    } catch (error) {
      console.error('Error booking appointment:', error);
    }
  };

  return (
    <div className="popup">
      {showPatientForm ? (
        <PatientForm onAddPatient={handleAddPatient} onClose={() => setShowPatientForm(false)} />
      ) : (
        <>
          <button className="close-button" onClick={onClose}>×</button>
          <div>
            <h2>Add Appointment</h2>
            <Form>
              <FormGroup>
                <FormLabel>From</FormLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    value={dayjs(start)}
                    onChange={(newValue) => setstart(newValue)}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                    className="date-picker-input"
                  />
                </LocalizationProvider>
              </FormGroup>
              <FormGroup>
                <FormLabel>To</FormLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    value={dayjs(end)}
                    onChange={(newValue) => setend(newValue)}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                    className="date-picker-input"
                  />
                </LocalizationProvider>
              </FormGroup>
              <FormGroup>
                <FormLabel>Doctor *</FormLabel>
                <Autocomplete
                  options={doctorOption} // Assuming options for doctors would be fetched similarly
                  value={selectedDoctor}
                  onChange={(e, newValue) => setSelectedDoctor(newValue)}
                  renderInput={(params) => (
                    <TextField {...params} variant="standard" fullWidth />
                  )}
                />
              </FormGroup>
              <FormGroup>
                <FormLabel>Patient *</FormLabel>
                <Autocomplete
                  options={patientOption}
                  value={selectedPatient}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      handleChange(newValue, [['PatientId', '_id'], ['PatientName', 'PatientName']]);
                      setSelectedPatient(newValue);
                    } else {
                      handleChange(null, [['PatientId', '_id']]);
                      setSelectedPatient('');
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant="standard" fullWidth />
                  )}
                />
              </FormGroup>
              <Button variant="primary" className="btn btn-primary mb-3 me-2 p-2 popup_button" onClick={() => setShowPatientForm(true)}>Add New Patient</Button>
              <Button variant="primary" className='popup_button' onClick={handleBookAppointment}>Confirm</Button>
              <Button variant="secondary" className='popup_button' onClick={onClose}>Cancel</Button>
            </Form>
          </div>
        </>
      )}
    </div>
  );
}

export default AppointmentForm;
