import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import Components from './components';
import { apiRequest } from '../api/apiUtils';
import { useState, useEffect } from 'react';
import LoginPage from '../views/modules/authentication/login';

export default function AppRoutes() {
  const [IndexRouters, setIndenRouters] = useState();
  let token = localStorage.getItem('token');
  let indexRoute = localStorage.getItem('indexRoute');

  useEffect(() => {
    const fetchInfo = async () => {
      // if (token?.length)
      await fetchData();
      console.log(IndexRouters)
    }
    fetchInfo();
  }, [token]);

  const fetchData = async () => {
    try {
      token = localStorage.getItem('token');
      indexRoute = localStorage.getItem('indexRoute');

      if (token) {
        if (indexRoute) {
          console.log(JSON.parse(indexRoute));
          setIndenRouters(JSON.parse(indexRoute));
        }
        else {
          let response;
          response = await apiRequest(`0_view_template/get/index`, 'GET', null, { 'Content-Type': 'multipart/form-data', 'Authorization': token });
          console.log(response.data[0]);
          localStorage.setItem('indexRoute', JSON.stringify(response.data));
          setIndenRouters(response.data);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <BrowserRouter basename='/'>
      <Routes key={token}>
        {IndexRouters?.map((route, index) => {
          return (
            <Route
              key={index}
              path={route.path}
              element={
                <PrivateRoute userroles={route?.availability}>
                  {Components(route)}
                </PrivateRoute>}
            >
              {
                route.children && route.children.map((childRoute, childIndex) => {
                  return (
                    <Route
                      key={childIndex}
                      path={childRoute.path}
                      element={
                        <PrivateRoute userroles={childRoute?.availability}>
                          {Components(childRoute)}
                        </PrivateRoute>}
                    />
                  )
                })
              }
            </Route>
          )
        })}
        <Route
          path='/sign-in'
          element={<LoginPage fetchData={fetchData} />}
        />
      </Routes>
      {!indexRoute && <Navigate to="/sign-in" />}
    </BrowserRouter>
  )
}
