import { useNavigate } from 'react-router-dom';

export const useLogout = () => {
  const navigate = useNavigate();

  const logout = () => {
    console.log("Logging out...");
    localStorage.clear();
    navigate('/sign-in');
  };

  return logout;
};