import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Col, Row } from "react-bootstrap";

import { apiRequest } from "../api/apiUtils";

export default function DashboardThree({ templateDb, templateKey, dataDb, dataId }) {
  const [template, setTemplate] = useState({});
  const [data, setData] = useState({});
  let token = localStorage.getItem("token");
  let navigate = useNavigate();

  const fetchData = async () => {
    console.log(dataDb);
    try {
      const response = await apiRequest(`${dataDb}/get/${dataId}`, 'GET', null, { 'Content-Type': 'multipart/form-data', 'Authorization': token });
      console.log(response.data[0]);
      setData(response.data[0]);
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error.message === 'Invalid Token') {
        console.log("error called");
        navigate('/sign-in?token=Invalid-Token');
      }
    }
  };

  const fetchTemplate = async () => {
    try {
      const response = await apiRequest(`${templateDb}/get/${templateKey}`, 'GET', null, { 'Content-Type': 'multipart/form-data', 'Authorization': token });
      console.log(response.data[0]);
      setTemplate(response.data[0]);
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error.message === 'Invalid Token') {
        console.log("error called");
        navigate('/sign-in?token=Invalid-Token');
      }
    }
  };

  useEffect(() => {
    const fetchInfo = async () => {
      await fetchTemplate();
      if (dataId) {
        await fetchData();
      }
    }
    fetchInfo();
  }, []);

  return (
    <Fragment>
      <div className="iq-card">
        {
          template.title ? (
            <div className="iq-card-header d-flex justify-content-between">
              <div className="iq-header-title">
                <h4 className="card-title">{template.title}</h4>
              </div>
            </div>
          ) : (<></>)
        }
        <div className="iq-card-body">
          <div className="about-info m-0 p-0">
            {
              template.fields?.map(inputs => {
                return (
                  <Row className="fs-5">
                    <Col className="col-12 col-md-6">{inputs.field_label}</Col>
                    <Col className="col-12 col-md-6">{data[inputs.field_name]}</Col>
                  </Row>
                )
              })
            }

          </div>
        </div>
      </div>
    </Fragment>
  );
};
