import axios from 'axios';

export const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_BASE_URL,
  // headers: {'Content-Type': 'multipart/form-data',},
});
// const currentSubdomain = extractSubdomain();
// console.log(instance.defaults.baseURL);

// // Set the subdomain-specific header in the Axios instance
// instance.defaults.headers.common['X-Subdomain'] = currentSubdomain;

export const apiRequest = async (url, method = 'GET', data = null, headers = {}) => {
  let hostname = window.location.hostname;
  let subdomain = hostname.split('.')[0];
  // let subdomain = "Master";
  // console.log(subdomain);
  const fullURL = `${instance.defaults.baseURL}${subdomain}/${url}`;
  console.log(fullURL)
  try {
    const response = await instance.request({
      url: fullURL,
      method,
      data,
      headers
    });
    return response;
  } catch (error) {
    if (error.response.data.message === 'Invalid token') {
      console.log('Invalid');
      localStorage.clear();
      throw new Error('Invalid Token');
    }
    console.error('API request error:', error.response.data);
    throw new Error('API request failed');
  }
};
