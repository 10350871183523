import { useState, useContext, memo, Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Accordion,
  useAccordionButton,
  AccordionContext,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import SidebarMenu from "./sidebar-menu";
import { apiRequest } from "../../../api/apiUtils";
import { useNavigate } from "react-router-dom";

let token, role;

function CustomToggle({ children, eventKey, onClick }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(eventKey, (active) =>
    onClick({ state: !active, eventKey: eventKey })
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <Link
      to="#"
      aria-expanded={isCurrentEventKey ? "true" : "false"}
      className="nav-link"
      role="button"
      onClick={(e) => {
        decoratedOnClick(isCurrentEventKey);
      }}
    >
      {children}
    </Link>
  );
}

const Item = ({ item }) => {
  if (item.availability) {
    if (item?.availability.includes(role))
      return (
        <SidebarMenu isTag="true" pathname={item.pathname} title={item.title} >
          <i className={item.icon} />
        </SidebarMenu>
      )
  }
  else {
    return (
      <SidebarMenu isTag="true" pathname={item.pathname} title={item.title} >
        <i className={item.icon} />
      </SidebarMenu>
    )
  }
}

const ItemList = ({ item, active, setActive, activeMenu, setActiveMenu }) => {
  return (
    <Accordion.Item as="li" eventKey={`${item.title}-menu`} bsPrefix={`${active === item.title ? "active menu-open" : ""} `} onClick={() => setActive(item.title)}>
      <CustomToggle eventKey={`${item.title}-menu`} onClick={(activeKey) => setActiveMenu(activeKey)} >
        <OverlayTrigger placement="right" overlay={<Tooltip>{item.title}</Tooltip>}>
          <i className={item.icon} />
        </OverlayTrigger>
        <span className="item-name">{item.title}</span>
        <i className="ri-arrow-right-s-line iq-arrow-right" />
      </CustomToggle>
      <Accordion.Collapse eventKey={`${item.title}-menu`}>
        <ul className="iq-submenu collapse menu-open">
          {
            item?.subItems?.map(subItem => {
              return (
                <Item item={subItem} />
              )
            })
          }
        </ul>
      </Accordion.Collapse>
    </Accordion.Item>
  )
}

const VerticalNav = memo(() => {
  const [activeMenu, setActiveMenu] = useState(false);
  const [active, setActive] = useState("");
  const [navigation, setNavigation] = useState();
  token = localStorage.getItem('token');
  role = localStorage.getItem('userRole');
  let navigate = useNavigate();

  useEffect(() => {
    const fetchInfo = async () => {
      token = localStorage.getItem('token');
      role = localStorage.getItem('userRole');
      await fetchData();
    }
    fetchInfo();
  }, [token]);

  const fetchData = async () => {
    try {
      let response;
      response = await apiRequest(`0_view_template/get/navigation`, 'GET', null, { 'Content-Type': 'multipart/form-data', 'Authorization': token });
      console.log(response.data[0]);
      setNavigation(response.data[0]);
    } catch (error) {
      console.error("Error updating event:", error);
      if (error.message === "Invalid Token") {
        console.log("error called");
        navigate("/sign-in?token=Invalid-Token");
      }
    }
  };

  return (
    <Fragment>
      <div id="sidebar-scrollbar">
        <nav className="iq-sidebar-menu">
          <Accordion as="ul" className="iq-menu">
            {
              navigation?.fields?.map(item => {
                if (item.subItems) {
                  if (item?.availability) {
                    if (item?.availability.includes(role))
                      return (
                        <ItemList item={item} active={active} setActive={setActive} activeMenu={activeMenu} setActiveMenu={setActiveMenu} />
                      )
                  }
                  else {
                    return (
                      <ItemList item={item} active={active} setActive={setActive} activeMenu={activeMenu} setActiveMenu={setActiveMenu} />
                    )
                  }
                }
                else {
                  return (
                    <Item item={item} />
                  )
                }
              })
            }
          </Accordion>
        </nav>
        <div className="p-3"></div>
      </div>
    </Fragment>
  );
});

VerticalNav.displayName = "VerticalNav";
export default VerticalNav;
