import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import LoginPage from '../views/modules/authentication/login';
const PrivateRoute = ({ children, userroles }) => {
  const navigate = useNavigate();
  let token = localStorage.getItem('token');
  console.log(token);
  let role = localStorage.getItem('userRole');

  useEffect(() => {
    if (!token) {
      navigate('/sign-in');
    }
  }, [token]);


  if (role && token) {
    if (userroles) {
      if (userroles.includes(role)) {
        return children
      } else {
        return <>Route not accessible</>;
      }
    } else {
      return children
    }
  }

  return <LoginPage />;
};

export default PrivateRoute;