import { Fragment, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import DashboardThree from "../../../views/dashboard-3";
import DataTable from "../../../views/modules/table/data_table";
import FormValidation from "../../../views/modules/forms/form-validation";
import Prescription from "./Table/PrescriptionTable";
import PrintPresciption from "./PrintPrescription";
import Bill from "./Table/BillTable";
import PrintInvoice from "./PrintInvoice";
import Payment from "./Table/PaymentTable";
import ApexChartPage from "../../../views/modules/charts/apexchart";

export default function Visit() {
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get('PatientId');
  const visit = queryParams.get('VisitId');
  const [billChanged, setBillChanged] = useState(false);
  const navigate = useNavigate();

  const handleBillChange = () => {
    setBillChanged(prevState => !prevState);
  };

  const initialCategories = [];

  const initialSeries = [
    {
      name: "Systolic",
      data: []
    },
    {
      name: "Diastolic",
      data: []
    },
    {
      name: "Heart Rate",
      data: []
    },
  ];


  return (
    <Fragment>
      <Row>
        <Col lg="4">
          <DashboardThree templateDb="0_view_template" templateKey="patient" dataDb="patient" dataId={id} />
        </Col>
        <Col lg="8">
          <DataTable templateDb="0_table_template" dataDb="visit_view" templateKey="patient_visit" dataId={id} />
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormValidation templateDb="0_form_template" dataDb="visit" templateKey="clinic_notes" dataId={visit} />
        </Col>
        <Col lg="6">
          <FormValidation templateDb="0_form_template" dataDb="patient" templateKey="patient_tag" dataId={id} />
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <ApexChartPage initialSeries={initialSeries} initialCategories={initialCategories} />
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <Prescription templateDb="0_table_template" dataDb="visit" templateKey="prescription" dataId={visit} />
        </Col>
        <Col lg="12">
          <div className="d-flex justify-content-center mb-3">
            <PrintPresciption visit={visit} />
            {/* <Button onClick={printPrescription}>Print Prescription</Button> */}
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <Bill templateDb="0_table_template" dataDb="visit" templateKey="bill" dataId={visit} onBillChange={handleBillChange} />
        </Col>
        <Col lg="12">
          <div className="d-flex justify-content-center mb-3">
            {/* <Button onClick={printBill}>Print Bill</Button> */}
            <PrintInvoice visit={visit} billChanged={billChanged} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <Payment templateDb="0_table_template" dataDb="visit" templateKey="payment" dataId={visit} billChanged={billChanged} />
        </Col>
      </Row>
    </Fragment>
  );
};
