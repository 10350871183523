import React from "react";
import BlankPage from "../views/modules/extra-pages/blank-page";
import Form from "../components/partials/Body/Forms/Form";
import DefaultLayout from "../layouts/default-layout";
import Table from "../components/partials/Body/Table/Table";
import Calendar from "../views/modules/calendar/calendar";

import VisitForm from "../components/partials/Body/Forms/VisitForm";
import Visit from "../components/partials/Body/Visit";

const Components = {
  DefaultLayout: DefaultLayout,
  BlankPage: BlankPage,
  Form: Form,
  Table: Table,
  Calendar: Calendar,
  VisitForm: VisitForm,
  Visit: Visit
};
 
export default block => {
  // component does exist
  if (typeof Components[block.element] !== "undefined") {
    return React.createElement(Components[block.element], {
      block: block
    });
  }
  // element doesn't exist yet
  return React.createElement(
    () => <div>The element {block.element} has not been created yet.</div>,
  );
}