import React from 'react';
import { DatePicker } from 'rsuite';

export const Default = React.forwardRef((props, ref) => {
  return (
    <div ref={ref} className="d-none d-print-block invoice-container">
      <div className="invoice-header">
        <h2>PRESCRIPTION</h2>
      </div>
      <table className="invoice-table">
        <tbody>
          <tr>
            <td className='align-top'>
              <div>{props.clinicInfo?.ClinicName}</div>
              <div>
                <div>{props.clinicInfo?.ClinicAddress}</div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <table className="invoice-table">
        <tbody>
          <tr>
            <td className='align-top'>
              <p><b>Patient Information</b></p>
              <p>Name: {props.data?.patients[0].PatientName}</p>
              <p>Contact: {props.data?.patients[0].mobile}</p>
              <p>Date of birth: <DatePicker
                value={props.data?.patients[0].date_of_birth ? new Date(props.data?.patients[0].date_of_birth) : ''}
                format={localStorage.getItem('dateFormat')}
                plaintext
              /></p>
            </td>
            <td className='align-top'>
              <p><b>Doctor Information</b></p>
              <p>Name: {props.data?.doctors[0].DoctorName}</p>
              <p>Contact: {props.data?.doctors[0].mobile}</p>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="pb-2">
        <p><b>Clinic Notes</b></p>
        <p>{props.data?.clinic_notes}</p>
      </div>
      <h5 className='pb-2'>Medicine Details</h5>
      <table style={{ tableLayout: 'fixed' }} className="table-bordered invoice-table">
        <thead>
          <tr>
            {
              props.tableTemplate?.fields?.map((column, index) => {
                return (
                  <th className="invoice-cell text-center" key={index}>{column.field_label}</th>
                )
              })
            }
          </tr>
        </thead>
        <tbody>
          {
            props.data?.prescription?.prescription_items?.map((row, index) => {
              return (
                <tr key={index}>
                  {
                    props.tableTemplate?.fields?.map((column, index) => {
                      return (
                        <td className={`invoice-cell ${column.properties?.class}`}>
                          {row[column.field_name]}
                        </td>
                      )
                    })
                  }
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </div>
  )
})