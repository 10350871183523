import 'rsuite/dist/rsuite.min.css';
import { Table, Button, DatePicker } from 'rsuite';
import { apiRequest } from "../../../api/apiUtils";
import { FormControl } from "react-bootstrap";
import CurrencyFormat from 'react-currency-format';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import dateFormat, { masks } from "dateformat";

const { Cell } = Table;
let filter = createFilterOptions();

let currency_format = JSON.parse(localStorage.getItem("currency"));

const EditableCell = ({ rowData, dataKey, inputs, dataState, setDataState, setError, ...props }) => {
  const editing = rowData.status === 'EDIT';
  let inputElement;
  switch (inputs?.field_type) {
    case "Currency":
      inputElement = <CurrencyFormat
        displayType={inputs.edit ? 'input' : 'text'}
        className={inputs.edit ? `rs-input ${inputs.properties?.class}` : ``}
        value={rowData[dataKey] || ''}
        {...currency_format}
        onValueChange={(values) => {
          const { formattedValue, value } = values;
          console.log(values)
          handleChange && handleChange(rowData.id, dataKey, values, dataState, setDataState, setError, [[[inputs.field_name], "value"]]);
        }}
      />;
      break;
    case "autocomplete":
      inputElement = <Autocomplete
        freeSolo={inputs.properties?.type_select ? true : false}
        options={inputs.field_options}
        disableClearable
        handleHomeEndKeys
        value={inputs.field_options?.find(option => option.value === rowData[dataKey]) || rowData[dataKey] || null}
        onInputChange={(e, newValue) => {
          let matchedOption = inputs.field_options?.find(option => option.label === newValue);
          if (!matchedOption) {
            let newOption = { newValue, value: newValue, label: newValue, ...rowData };
            delete newOption.id;
            delete newOption.status;
            matchedOption = newOption;
          }
          console.log(matchedOption);
          if (inputs.fields_dependent) {
            if (matchedOption)
              handleChange && handleChange(rowData.id, dataKey, matchedOption, dataState, setDataState, setError, [[[inputs.field_name], "value"], ...inputs.fields_dependent]);
            else if (matchedOption && matchedOption.inputValue)
              handleChange && handleChange(rowData.id, dataKey, matchedOption.inputValue, dataState, setDataState, setError, [[[inputs.field_name], "value"]]);
            else
              handleChange && handleChange(rowData.id, dataKey, null, dataState, setDataState, setError, [[[inputs.field_name], "value"], ...inputs.fields_dependent]);
          } else {
            if (matchedOption)
              handleChange && handleChange(rowData.id, dataKey, matchedOption, dataState, setDataState, setError, [[[inputs.field_name], "value"]]);
            else if (matchedOption && matchedOption.inputValue)
              handleChange && handleChange(rowData.id, dataKey, matchedOption.inputValue, dataState, setDataState, setError, [[[inputs.field_name], "value"]]);
            else
              handleChange && handleChange(rowData.id, dataKey, null, dataState, setDataState, setError, [[[inputs.field_name], "value"]]);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          const { inputValue } = params;
          const isExisting = options.some((option) => inputValue === option.value);
          if (inputValue !== '' && !isExisting && inputs?.properties?.type_select) {
            filtered.push({
              inputValue,
              value: inputValue,
              label: inputValue,
            });
            console.log(filtered)
          }
          return filtered;
        }}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string')
            return option;
          // Add option created dynamically
          if (option.inputValue)
            return option.inputValue;
          // Regular option
          return option.label;
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            fullWidth
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              classes: {
                root: 'form-control',
                input: 'form-control',
              },
            }}
          />
        )}
        ListboxProps={{
          style: { fontSize: 'smaller' },
        }}
      />
      break;

    // case "date":
    //   inputElement = <DatePicker
    //     type={inputs.field_type}
    //     // className={`rs-input ${inputs.properties.class}`}
    //     // value={new Date(defaultValue) || ''}
    //     value={rowData[dataKey] ? new Date(rowData[dataKey]) : ''}
    //     format="dd/MMM/yyyy"
    //     oneTap
    //     cleanable={false}
    //     onChange={event => {
    //       handleChange && handleChange(rowData.id, dataKey, event, dataState, setDataState, setError, [[[inputs.field_name], "value"]]);
    //     }}
    //   />
    //   break;
    default:
      if (inputs.edit) {
        inputElement = <FormControl
          type={inputs.field_type}
          className={`rs-input ${inputs.properties?.class}`}
          value={rowData[dataKey] || ''}
          onChange={event => {
            handleChange && handleChange(rowData.id, dataKey, event.target, dataState, setDataState, setError, [[[inputs.field_name], "value"]]);
          }}
        />;
      } else {
        inputElement = <span className="table-content-edit-span">
          {rowData[dataKey]}
        </span>;
      }
      break;
  }

  let output;
  switch (inputs?.field_type) {
    case "Currency":
      output = <span className="table-content-edit-span">
        <CurrencyFormat
          displayType='text'
          value={rowData[dataKey] || ''}
          {...currency_format}
        />
      </span>
      break;
    case "date":
      output = <span className="table-content-edit-span">
        <DatePicker
          value={rowData[dataKey] ? new Date(rowData[dataKey]) : ''}
          format={localStorage.getItem('dateFormat')}
          plaintext
        />
      </span>
      break;
    case "time":
      {
        if (rowData[dataKey]) {
          const [hours, minutes] = rowData[dataKey].split(':');
          let date = new Date();
          date.setHours(parseInt(hours));
          date.setMinutes(parseInt(minutes));
          output = <span className="table-content-edit-span">
            {dateFormat(date, localStorage.getItem('timeFormat'))}
          </span>
        }
      }
      break;
    default:
      output = <span className="table-content-edit-span">
        {rowData[dataKey]}
      </span>
      break;
  }
  return (
    <Cell {...props} className={editing ? 'table-content-editing' : ''}>
      {editing ? (
        <div className={`${inputs.properties?.class}`}>
          {inputElement}
        </div>
      ) : (
        <div className={`${inputs.properties?.class}`}>
          {output}
        </div>
      )}
    </Cell>
  );
};

const ActionCell = ({ rowData, dataKey, handleSubmit, generateDefaultData, dataState, setDataState, fetchedData, setFetchedData, fields, setError, ...props }) => {
  return (
    <Cell {...props} style={{ padding: '6px' }}>
      {dataKey === 'save' && (
        <Button
          appearance="link"
          onClick={() => {
            setError('');
            if (rowData.status === 'EDIT') {
              console.log(rowData.status)
              const validationResult = validateRowData(rowData, fields);
              if (validationResult !== true) {
                const message = `Please enter valid ${validationResult.join(', ')}.`;
                setError(message);
                return;
              }
              handleEditState(dataState, setDataState, rowData.id);
              if (!fetchedData.some(item => item.id === rowData.id))
                addNewRow(dataState, setDataState, generateDefaultData);
              handleSave(fetchedData, setFetchedData, rowData.id, rowData, handleSubmit);
            } else {
              console.log(rowData.status)
              handleEditState(dataState, setDataState, rowData.id);
            }
          }}
        >
          {rowData.status === 'EDIT' ? 'Save' : 'Edit'}
        </Button>
      )}
      {dataKey === 'delete' && (
        < Button
          appearance="link"
          onClick={() => {
            handleDelete(dataState, setDataState, rowData.id, setError);
            handleSave(fetchedData, setFetchedData, rowData.id, rowData, handleSubmit, true);
          }}
        >
          Delete
        </Button>
      )}
    </Cell >
  );
};

const fetchData = async (database, id, jwtToken, navigate) => {
  try {
    const response = await apiRequest(`${database}/get/${id}`, 'GET', null, { 'Content-Type': 'multipart/form-data', 'Authorization': jwtToken });
    return response.data[0];
  } catch (error) {
    console.error('Error fetching data:', error);
    if (error.message === 'Invalid Token') {
      console.log("error called");
      navigate('/sign-in?token=Invalid-Token');
    }
  }
};

const addNewRow = (dataState, setDataState, generateDefaultData) => {
  const currentData = Array.isArray(dataState) ? dataState : [];
  const newRow = generateDefaultData();
  setDataState([newRow, ...currentData]);
};

const handleEditState = (dataState, setDataState, id) => {
  const nextData = Object.assign([], dataState);
  const activeItem = nextData.find(item => item.id === id);
  activeItem.status = activeItem.status === "EDIT" ? "SAVE" : 'EDIT';
  setDataState(nextData);
};

const handleDelete = async (dataState, setDataState, id, setError) => {
  const nextData = Object.assign([], dataState);
  const index = nextData.findIndex(item => item.id === id);
  if (index !== -1) {
    nextData.splice(index, 1);
    setDataState(nextData);
    setError('');
  }
};

const handleChange = async (id, key, value, dataState, setDataState, setError, dependentFields) => {
  console.log(value)
  const nextData = Object.assign([], dataState);
  const activeItem = nextData.find(item => item.id === id);
  setError('');

  if (dependentFields && dependentFields.length > 0) {
    dependentFields.forEach(dependentField => {
      if (value !== null)
        if (Array.isArray(dependentField))
          activeItem[dependentField[0]] = typeof value[dependentField[1]] === 'object' ? value[dependentField[1]][0] : value[dependentField[1]]
        else
          activeItem[dependentField] = value[dependentField];
      else
        activeItem[dependentField] = null
    });
  }

  if ((key === 'price' || key === 'quantity') && activeItem['price'] && activeItem['quantity']) {
    activeItem['amount'] = parseFloat(activeItem['price']) * parseFloat(activeItem['quantity']);
  }
  setDataState(nextData);
};

const validateRowData = (rowData, fields) => {
  let requiredFields = [];
  requiredFields = fields?.filter(field => field.properties?.required);
  let invalidFields = [];
  for (const field of requiredFields)
    if (!rowData[field.field_name])
      invalidFields.push(field.field_label);
  if (invalidFields.length === 0)
    return true;
  else
    return invalidFields;
};

const handleSave = (fetchedData, setFetchedData, id, rowData, handleSubmit, deleteRow = false) => {
  const nextData = Object.assign([], fetchedData);
  const index = nextData?.findIndex(item => item.id === id);
  if (deleteRow) {
    if (index !== -1) {
      nextData.splice(index, 1);
    }
  }
  else if (index !== -1)
    nextData[index] = { ...nextData[index], ...rowData };
  else
    nextData.unshift({ id, ...rowData }); //To insert new row on front of JSON
  setFetchedData(nextData);
  handleSubmit();
};

const calculateTableHeight = (data) => {
  // Base height for the table
  const baseHeight = 50; // This is the minimum height of the table
  // Additional height per row
  const additionalHeightPerRow = 46; // Adjust this value based on your needs
  // Calculate the total height
  const totalHeight = baseHeight + (data.length * additionalHeightPerRow);
  // console.log(totalHeight);
  return Math.max(totalHeight, 100);
};

export {
  EditableCell,
  ActionCell,
  fetchData,
  addNewRow,
  handleEditState,
  handleDelete,
  handleChange,
  handleSave,
  calculateTableHeight
};