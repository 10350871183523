import React, { Fragment, useEffect, useState } from 'react'

import { Link } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import 'swiper/css'
import 'swiper/css/pagination';
import FormLogin from '../forms/form-login';
// Image
import logo from "../../../assets/images/logo_ww.png"
import { fetchLogo } from '../../../api/fetchLogo';

const LoginPage = ({ fetchData }) => {
    const [tokenError, setTokenError] = useState();
    const [logoUrl, setLogoUrl] = useState(null);

    const queryParams = new URLSearchParams(window.location.search);
    useEffect(() => {
        const loadLogo = async () => {
            const result = await fetchLogo();
            console.log(result)
            if (result != null)
                setLogoUrl(result);

            console.log(queryParams.get('token'));
            if (queryParams)
                setTokenError(queryParams.get('token'));
        };
        loadLogo();
    }, []);
    return (
        <Fragment>
            <section className="sign-in-page">
                <Container className="sign-in-page-bg mb-md-1">
                    <Link className="sign-in-logo" to="/">
                        {
                            (logoUrl != null) ?
                                <img src={logoUrl} className="rounded mx-auto d-block mt-1" alt="" /> :
                                <img src={logo} className="rounded mx-auto d-block mt-1" alt="" />}
                    </Link>
                    <div className="sign-in-detail text-black">
                        <div className="sign-in-from col-6 position-relative h-80">
                            {
                                tokenError && <p className='text-danger text-center font-weight-bold fs-5'>Logged out due to expired token</p>
                            }
                            <FormLogin templateDb="0_form_template" dataDb="user" templateKey="sign_in" className="m-100" fetchData={fetchData} />
                        </div>
                    </div>
                </Container>
            </section>
        </Fragment>
    )
}

export default LoginPage
