import React, { useState } from 'react';
import { apiRequest } from '../../../api/apiUtils';
import { Button, Form, FormGroup, FormLabel, FormControl } from 'react-bootstrap';

function PatientForm({ onAddPatient, onClose }) {
  const [PatientName, setName] = useState('');
  const [age, setAge] = useState('');
  const [address, setAddress] = useState('');
  const [mobile, setMobile] = useState('');
  let token = localStorage.getItem('token');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newPatient = { PatientName, age, address, mobile };

    const response = await apiRequest(
      'patient/post',
      'POST',
      newPatient,
      { 'Content-Type': 'application/json', Authorization: token }
    );
    console.log(response);
    const newOption = newPatient;

    newOption['label'] = `${newOption.PatientName} (${newOption.mobile})`;
    newOption['value'] = response.data.insertedId;
    newOption['_id'] = response.data.insertedId;

    onAddPatient(newOption);
  };

  return (
    <div className="popup">
      <button className="close-button" onClick={onClose}>×</button>
      <h2>Add New Patient</h2>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <FormLabel>Name:</FormLabel>
          <FormControl
            type="text"
            value={PatientName}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </FormGroup>
        <FormGroup>
          <FormLabel>Age:</FormLabel>
          <FormControl
            type="number"
            value={age}
            onChange={(e) => setAge(e.target.value)}
            required
          />
        </FormGroup>
        <FormGroup>
          <FormLabel>Address:</FormLabel>
          <FormControl
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            required
          />
        </FormGroup>
        <FormGroup>
          <FormLabel>Mobile:</FormLabel>
          <FormControl
            type="text"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
            required
          />
        </FormGroup>
        <Button variant="primary" type="submit" className="popup_button">Add Patient</Button>
        <Button variant="secondary" onClick={onClose} className="popup_button">Cancel</Button>
      </Form>
    </div>
  );
}

export default PatientForm;
