import { useParams } from "react-router-dom"
import FormValidation from '../../../../views/modules/forms/form-validation'

export default function Form(props) {
  const params = useParams();
  return (
    <div>
      {
        params.id ? (
          <FormValidation templateDb={props.block.templateDb} dataDb={props.block.dataDb} templateKey={props.block.templateKey} dataId={params.id} />
        ) : (
          <FormValidation templateDb={props.block.templateDb} dataDb={props.block.dataDb} templateKey={props.block.templateKey} />
        )
      }
    </div>
  )
}
