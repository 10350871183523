import React from 'react';
import CurrencyFormat from 'react-currency-format';
import '../assets/css/invoice.css'
import { DatePicker } from 'rsuite';

export const Default = React.forwardRef((props, ref) => {
  console.log(props.data, props.tableTemplate, props.clinicInfo);
  const currency_format = JSON.parse(localStorage.getItem('currency'))
  let dateFormat = localStorage.getItem('dateFormat')

  return (
    <div ref={ref} className="d-none d-print-block invoice-container">
      <div className="invoice-header">
        <h2>INVOICE</h2>
      </div>
      <table className="invoice-table">
        <tbody>
          <tr>
            <td className='align-top'>
              <div>{props.clinicInfo?.ClinicName}</div>
              <div>
                <div>{props.clinicInfo?.ClinicAddress}</div>
              </div>
            </td>
            <td className='align-top'>
              <div className='text-end'>Invoice Date: <DatePicker
                value={props.data?.bill?.date ? new Date(props.data?.bill?.date) : ''}
                format={dateFormat}
                plaintext
              /></div>
              <div className='text-end'>Invoice number: {props.data?.bill?.billNumber}</div>
            </td>
          </tr>
        </tbody>
      </table>
      <table className="invoice-table">
        <tbody>
          <tr>
            <td className='align-top'>
              <p><b>Patient Information</b></p>
              <p>Name: {props.data?.patients[0]?.PatientName}</p>
              <p>Contact: {props.data?.patients[0]?.mobile}</p>
              <p>Date of birth: <DatePicker
                value={props.data?.patients[0].date_of_birth ? new Date(props.data?.patients[0].date_of_birth) : ''}
                format={dateFormat}
                plaintext
              /></p>
            </td>
            <td className='align-top'>
              <p><b>Doctor Information</b></p>
              <p>Name: {props.data?.doctors[0].DoctorName}</p>
              <p>Contact: {props.data?.doctors[0].mobile}</p>
            </td>
          </tr>
        </tbody>
      </table>
      <h5 className='pb-2'>BILL</h5>
      <table style={{ tableLayout: 'fixed' }} className="table-bordered invoice-table">
        <thead>
          <tr>
            {
              props.tableTemplate?.fields?.map((column, index) => {
                return (
                  <th className="invoice-cell text-center" key={index}>{column.field_label}</th>
                )
              })
            }
          </tr>
        </thead>
        <tbody>
          {
            props.data?.bill?.bill_items?.map((row, index) => {
              return (
                <tr key={index}>
                  {
                    props.tableTemplate.fields?.map((column, index) => {
                      return (
                        <td className={`invoice-cell ${column.properties?.class}`}>
                          {
                            column.field_type === 'Currency' ? (
                              <CurrencyFormat
                                displayType='text'
                                value={row[column.field_name]}
                                {...currency_format}
                              />
                            ) : (
                              <>
                                {row[column.field_name]}
                              </>
                            )
                          }
                        </td>
                      )
                    })
                  }
                </tr>
              )
            })
          }
        </tbody>
      </table>
      <table style={{ tableLayout: 'fixed' }} className="invoice-table">
      {/* <table style={{ tableLayout: 'fixed' }} className="invoice-table"> */}
        <tbody>
          <tr className="total-row">
            <td colSpan={props.tableTemplate.fields.length - 3} className="invoice-cell border-less"></td>
            <td className="invoice-cell">Sub-Total</td>
            <td className="invoice-cell"></td>
            <td className="invoice-cell text-end">
              <CurrencyFormat
                displayType='text'
                value={props.data?.bill?.subtotal}
                {...currency_format}
              />
            </td>
          </tr>
          {
            props.data?.bill?.tax_items?.map((row, index) => {
              return (
                <tr>
                  <td colSpan={props.tableTemplate.fields.length - 3} className="invoice-cell"></td>
                  <td className="invoice-cell">{row.tax}</td>
                  <td className="invoice-cell text-end"> {row.tax_rate}% </td>
                  <td className="invoice-cell text-end">
                    <CurrencyFormat
                      displayType='text'
                      value={parseFloat(props.data?.bill?.subtotal * row.tax_rate / 100).toFixed(currency_format?.decimalScale || 2)}
                      {...currency_format}
                    />
                  </td>
                </tr>
              )
            })
          }
          <tr className="total-row">
            <td colSpan={props.tableTemplate.fields.length - 3} className="invoice-cell"></td>
            <td className="invoice-cell">Tax-Total</td>
            <td className="invoice-cell"></td>
            <td className="invoice-cell text-end">
              <CurrencyFormat
                displayType='text'
                value={props.data?.bill?.taxAmount}
                {...currency_format}
              />
            </td>
          </tr>
          <tr className="total-row">
            <td colSpan={props.tableTemplate.fields.length - 3} className="invoice-cell"></td>
            <td className="invoice-cell">Total</td>
            <td className="invoice-cell"></td>
            <td className="invoice-cell text-end">
              <CurrencyFormat
                displayType='text'
                value={props.data?.bill?.total}
                {...currency_format}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
})
